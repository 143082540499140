import { View, StyleSheet } from "react-native";
import { useRef, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import { useAppState, actions } from "../contexts/AppStateContext";

import JourneyCardList from "../components/journey/CareerPathCardList/JourneyCardList";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { PlusCircle } from "../components/svgIcons";
import {
  useJourneySubmitFormMutation,
  GetJourneysDocument,
  TodosDocument,
  useGetJourneysQuery,
} from "../graphql/generated/graphql";
import JourneyFormModalContainer from "../containers/journey/JourneyFormModalContainer/JourneyFormModalContainer";
import Loader from "../components/common/status/Loader/Loader";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import Sentry from "../utils/sentry";
import { shadowStyles } from "../styles/shadow";

function JourneysView() {
  const scrollViewRef = useRef(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const { dispatch } = useAppState();

  const { data: journeysData, loading: journeysLoading } = useGetJourneysQuery();

  const openModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalVisible(true);
  };
  const closeModal = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalVisible(false);
  };

  const [submitJourney, { loading }] = useJourneySubmitFormMutation({
    refetchQueries: [{ query: GetJourneysDocument }, { query: TodosDocument }],
  });

  const handleCreateJourney = async (description, date) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      await submitJourney({
        variables: {
          description: description,
          dueDate: date,
          timezone: timezone,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleJourneyPress = (journeyId) => {
    navigation.navigate("Journey", {
      id: journeyId,
    });
  };

  return (
    <>
      <View style={styles.headerContainer}>
        <JourneyFormModalContainer onSubmit={handleCreateJourney} visible={isModalVisible} onClose={closeModal} />
        <CustomButton
          onPress={openModal}
          text={" Create a New Journey"}
          styleType="primaryLight"
          leftIcon={<PlusCircle />}
          disabled={loading}
          rightIcon={null}
          style={[styles.addButton, shadowStyles.smallShadowButton]}
          textProps={null}
          textStyle={null}
          aria-label="Create a New Journey"
        />
      </View>
      <ScrollView ref={scrollViewRef} style={styles.scrollView} showsVerticalScrollIndicator={false}>
        {loading && (
          <View style={styles.loaderContainer}>
            <CustomText
              text="Your journey is being generated. Feel free to explore other areas while you wait."
              style={null}
            />
            <CustomSpacing type="vertical" />
            <Loader />
          </View>
        )}
        <JourneyCardList
          journeys={journeysData?.getJourneys}
          onJourneyPress={handleJourneyPress}
          journeysLoading={journeysLoading}
        />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
  loaderContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: 20,
  },
  addButton: {
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
  },
});

export default JourneysView;
