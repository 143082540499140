import { useEffect } from "react";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useNavigation } from "@react-navigation/native";
import { useChatBar } from "../contexts/ChatBarContext";
import { fbq } from "../utils/fbPixel";

function WebSocketNavigator() {
  const { state, dispatch } = useAppState();
  const navigation = useNavigation();
  const { setShowChat, setConversationType } = useChatBar();

  useEffect(() => {
    // Send the meta pixel when exiting onboarding
    if (state.meta.sendOnboardingPixelEvent) {
      fbq("track","StartTrial", {
        value: "0.00",
        currency: "USD",
        predicted_ltv: "100.00",
      });

      dispatch({
        type: actions.SET_META,
        payload: { sendOnboardingPixelEvent: false },
      });
    }
  }, [state.state.view, navigation, state.meta.sendOnboardingPixelEvent]);
}

export default WebSocketNavigator;
