import DefaultLayout from "../layouts/DefaultLayout";
import { View, StyleSheet, Platform } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import Loader from "../components/common/status/Loader/Loader";
import { useAppState, actions } from "../contexts/AppStateContext";
import { useState, useEffect, useRef } from "react";
import CustomText from "../components/common/general/CustomText/CustomText";
import { ScrollView } from "react-native-gesture-handler";
import CareerInfo from "../components/career/CareerInfo/CareerInfo";
import Divider from "../components/common/layout/Divider/Divider";
import CourseCardList from "../components/lms/CourseCardList/CourseCardList";
import CareerPathCardList from "../components/career/CareerPathCardList/CareerPathCardList";
import EditCareerDetails from "../components/career/EditCareerDetails/EditCareerDetails";
import CustomModal from "../components/common/general/CustomModal/CustomModal";
import { useNavigation } from "@react-navigation/native";
import {
  useCareerSubmitFormMutation,
  useGetLearningTreeQuery,
  useGetCareersQuery,
  useGenerateSuggestedCareersMutation,
  useGetCareerHeaderQuery,
  useSetCareerPathActiveMutation,
  useGetCareerTopicsQuery,
  GetCareerHeaderDocument,
  GetCareerTopicsDocument,
  useMeQuery,
} from "../graphql/generated/graphql";
import Sentry from "../utils/sentry";

function CareerView() {
  const { isMedium } = useResponsiveScreen();
  const scrollViewRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const { data: userData, refetch } = useMeQuery({ pollInterval: 5000 });

  const { dispatch } = useAppState();
  const navigation = useNavigation();

  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [activeCareerId, setActiveCareerId] = useState("");
  const [occupation, setOccupation] = useState("");
  const [careerGoal, setCareerGoal] = useState("");
  const [activeName, setActiveName] = useState("");

  const { data, error, refetch: refetchCareers } = useGetCareersQuery({ fetchPolicy: "network-only" });

  const { data: careerHeaderData } = useGetCareerHeaderQuery();
  const { data: careerTopics } = useGetCareerTopicsQuery({
    variables: { careerId: activeCareerId },
    skip: !activeCareerId,
  });
  const { refetch: refetchLearningTree } = useGetLearningTreeQuery({
    variables: { level: null, name: null },
    skip: true,
  });

  const [careerSubmitForm] = useCareerSubmitFormMutation();
  const [setCareerPathActive] = useSetCareerPathActiveMutation({
    refetchQueries: [
      {
        query: GetCareerHeaderDocument,
      },
      {
        query: GetCareerTopicsDocument,
      },
    ],
  });
  const [generateSuggestedCareers] = useGenerateSuggestedCareersMutation();

  useEffect(() => {
    if (userData && !userData.me.isCareerLoading) {
      setIsLoading(false);

      refetchCareers();
    }

    if (Platform.OS === "ios" && !userData.me.isCareerLoading) {
      announceForAccessibility({ message: "Generate career button ready", queue: true });
    }
  }, [userData, refetchCareers]);

  useEffect(() => {
    if (careerHeaderData) {
      const { getCareerHeader } = careerHeaderData;
      setCareerGoal(getCareerHeader.careerGoal || "");
      setOccupation(getCareerHeader.jobTitle || "");
      setActiveName(getCareerHeader.careerName || "");
      setActiveCareerId(getCareerHeader.careerId || "");
    }
  }, [careerHeaderData]);

  let courseData = [];
  if (careerTopics) {
    courseData = careerTopics?.getCareerTopics.map((topic, index) => ({
      label: activeName,
      title: topic.name,
      description: topic.description,
      id: index,
      fillPercentage: topic.mastery,
    }));
  }

  let hasCareer = false;
  let careers = [];

  if (data) {
    careers = data.getCareers;
    hasCareer = careers.length;
  }

  const enrolledTracks = userData?.me?.enrolledTracks || [];

  const handleNavigation = (id) => {
    navigation.navigate("CareerDetails", { id });
  };

  const handleFocus = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
  };

  const handleEditPress = () => {
    setEditModalVisible(true);
  };

  const handleModalClose = () => {
    setEditModalVisible(false);
  };

  const handleSetActive = async (careerId) => {
    try {
      await setCareerPathActive({
        variables: {
          careerId,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleRemovePath = async () => {
    try {
      await setCareerPathActive({
        variables: {
          careerId: null,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleSaveDetails = async (newOccupation, newCareerGoal, newCareerPath) => {
    try {
      await careerSubmitForm({
        variables: {
          currentOccupation: newOccupation,
          careerPathName: newCareerPath,
          careerGoal: newCareerGoal,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }

    setOccupation(newOccupation);
    setCareerGoal(newCareerGoal);
    setActiveName(newCareerPath);
    handleModalClose();
  };

  const handleCoursePress = async (id) => {
    const name = courseData[id].title;

    const treeDate = await refetchLearningTree({
      level: "Topic",
      name: name,
    });

    const tree = treeDate?.data?.getLearningTree;

    if (enrolledTracks.includes(name)) {
      navigation.navigate("Lesson", {
        field: tree.FieldName,
        subfield: tree.SubfieldName,
        topic: tree.TopicName,
      });
    } else {
      navigation.navigate("Track Overview", {
        field: tree.FieldName,
        subfield: tree.SubfieldName,
        topic: tree.TopicName,
      });
    }
  };

  const handleGenerateButtonPress = async () => {
    setIsLoading(true);

    refetch();
    scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
    await generateSuggestedCareers();
  };

  return (
    <DefaultLayout>
      <View style={styles.headerContainer}>
        <CustomText role="header" text="My Career" size={isMedium ? "l" : "xs"} textType="display" weight="ultraBold" />
        <CareerInfo occupation={occupation} goal={careerGoal} careerPath={activeName} editPress={handleEditPress} />
        <Divider type="vertical" />
      </View>
      <CustomModal
        onRequestClose={handleModalClose}
        showCloseButton="true"
        title="Edit Career Details"
        visible={isEditModalVisible}
        style={styles.modalContainer}>
        <EditCareerDetails
          onFocus={handleFocus}
          onRequestClose={handleModalClose}
          occupation={occupation}
          selectedItem={careerGoal}
          activePath={activeName}
          savePress={handleSaveDetails}
          removePath={handleRemovePath}
        />
      </CustomModal>
      <ScrollView ref={scrollViewRef} style={styles.scrollView} showsVerticalScrollIndicator={false}>
        {activeName ? (
          <>
            <View>
              <CustomText
                style={{ paddingTop: 20 }}
                text="Recommended Tracks"
                weight={isMedium ? "ultraBold" : "bold"}
                size={isMedium ? "s" : "xs"}
                textType="display"
              />
              <CourseCardList courses={courseData} onPress={handleCoursePress} />
            </View>
          </>
        ) : (
          <View>
            <View
              style={{
                alignItems: isMedium ? "flex-start" : "center",
              }}>
              {hasCareer ? (
                <CustomText
                  style={styles.headerText}
                  text="Choose a career path to begin learning"
                  weight={isMedium ? "ultraBold" : "bold"}
                  size={isMedium ? "s" : "xs"}
                  textType="display"
                />
              ) : (
                <CustomText
                  text="Generate Your Tailored Career Path"
                  weight={isMedium ? "ultraBold" : "bold"}
                  size={isMedium ? "s" : "xs"}
                  textType="display"
                />
              )}
            </View>

            {userData?.me?.isCareerLoading && (
              <View style={styles.loaderContainer}>
                <Loader size="large" color="#0000ff" />
                <CustomText
                  style={styles.headerText}
                  text="New careers paths are being generated for you! Come back in a few minutes. This may take a while..."
                  size="l"
                />
              </View>
            )}

            <CareerPathCardList
              isCareerLoading={userData?.me?.isCareerLoading}
              hasCareer={hasCareer}
              navigation={handleNavigation}
              onPressSetActive={handleSetActive}
              careerPathCardData={careers}
              onButtonPress={handleGenerateButtonPress}
              loading={isLoading}
              error={error}
            />
          </View>
        )}
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    width: "90%",
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
  },
  headerContainer: {
    flexDirection: "column",
  },
  headerText: {
    paddingTop: 20,
    paddingBottom: 10,
    textAlign: "center",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CareerView;
