import React from "react";
import { View } from "react-native";

import AssignmentCard from "../AssignmentCard/AssignmentCard";

import ScrollCarousel from "../ScrollCarousel/ScrollCarousel";
import { classCardColors } from "../../../constants/classCardColors";

const AssignmentCardList = ({ assignments }) => {
  console.log("assignments", assignments);
  let color = "";
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let day = "";

  return (
    <ScrollCarousel data={assignments} cardWidth={245} padding={10}>
      {assignments.map((assignment, index) => {
        for (let i = 0; i < classCardColors.length; i++) {
          if (assignment.color == classCardColors[i].id) {
            color = classCardColors[i].color;
          } else {
            color = classCardColors[0].color;
          }
        }
        let date = new Date(assignment.due_date);
        day = daysOfWeek[date.getUTCDay()];

        return (
          <View
            id={index}
            key={index}
            // style={styles.cardContainer}
            aria-label={`Assignment due on ${day} for ${assignment.className}`}>
            <AssignmentCard
              dueDay={day}
              color={color}
              subject={assignment.className}
              chapter={assignment.title}
              resourceId={assignment.id}
              courseId={assignment.classId}
              integrationId={assignment.integrationId}
            />
          </View>
        );
      })}
    </ScrollCarousel>
  );
};

export default AssignmentCardList;
