/* eslint-disable react-native/no-unused-styles */
import { View, StyleSheet, Dimensions } from "react-native";
import { MotiView } from "moti";
import { useAppState } from "../contexts/AppStateContext";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import MainMenuContainer from "../containers/navigation/MainMenuContainer/MainMenuContainer";
import ChatBarContainer, { viewsWithoutChatWindow } from "../containers/navigation/ChatBarContainer/ChatBarContainer";
import MobileHeaderContainer from "../containers/navigation/MobileHeaderContainer/MobileHeaderContainer";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import LearnMenuContainer from "../containers/navigation/LearnMenuContainer/LearnMenuContainer";
import { useRoute } from "@react-navigation/native";
import ChatWindowContainer from "../containers/chatWindow/ChatWindowContainer/ChatWindowContainer";
import { useChatBar } from "../contexts/ChatBarContext";

const height = Dimensions.get("window").height;

function DefaultLayout({ children, showLearnMenu = false }) {
  const { state } = useAppState();
  const { isMedium } = useResponsiveScreen();
  const route = useRoute();
  const { showChat } = useChatBar();

  const showChatWindow = showChat && !viewsWithoutChatWindow.includes(route.name);

  return (
    <View style={styles().container}>
      {isMedium ? (
        <View style={styles().largeContainer} aria-hidden={showChatWindow}>
          {showLearnMenu ? <LearnMenuContainer /> : <MainMenuContainer />}

          <View style={styles().centerWrapper}>
            <View style={styles().childrenContainer} aria-live="polite">
              {children}
            </View>
            <MotiView
              style={styles().chatBarContainer}
              animate={{
                scale: state.meta.isExpanded ? 1 : 1,
                opacity: state.meta.isExpanded ? 1 : 1,
              }}
              transition={{ type: "timing", duration: 650 }}>
              {!showChat && <ChatBarContainer />}
            </MotiView>
          </View>
        </View>
      ) : (
        <View style={styles().smallContainer} aria-hidden={showChatWindow}>
          <MobileHeaderContainer isShowLearn={showLearnMenu} />
          <CustomSpacing type="vertical" size="l" />
          <View
            style={styles().mobileChildrenContainer}
            aria-live="polite"
            aria-hidden={state.meta.isLearnMenuOpen || state.meta.isMainMenuExpanded}>
            {children}
          </View>

          <MotiView
            id="main-menu"
            style={styles().mobileMenuContainer}
            transition={{ delay: 400, damping: 15, mass: 1 }}
            from={{
              translateX: -600,
            }}
            animate={{
              translateX: state.meta.isMainMenuExpanded ? 0 : -600,
            }}
            aria-hidden={!state.meta.isMainMenuExpanded}>
            <MainMenuContainer />
          </MotiView>

          {showLearnMenu && (
            <MotiView
              id="learn-menu"
              style={styles().mobileMenuContainer}
              transition={{ delay: 400, damping: 15, mass: 1 }}
              from={{
                translateX: -600,
              }}
              animate={{
                translateX: state.meta.isLearnMenuOpen ? 0 : -600,
              }}
              aria-hidden={!state.meta.isLearnMenuOpen}>
              <LearnMenuContainer />
            </MotiView>
          )}

          <MotiView
            id="chat-bar"
            style={styles().chatBarContainer}
            animate={{
              scale: state.meta.isExpanded ? 1 : 1,
              opacity: state.meta.isExpanded ? 1 : 1,
            }}
            transition={{ type: "timing", duration: 650 }}
            aria-hidden={state.meta.isLearnMenuOpen || state.meta.isMainMenuExpanded}>
            {!showChat && <ChatBarContainer />}
          </MotiView>
        </View>
      )}
      {showChatWindow && (
        <MotiView
          from={{ translateY: height }}
          animate={{ translateY: 0 }}
          exit={{ translateY: -100 }}
          transition={{
            type: "timing",
            duration: 450,
          }}
          removeClippedSubviews={true}
          style={styles().chatWindowContainer}
          aria-hidden={!showChatWindow}>
          <ChatWindowContainer />
        </MotiView>
      )}
    </View>
  );
}

const styles = () =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: "#fff",
    },
    largeContainer: {
      flex: 1,
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    smallContainer: {
      flex: 1,
      backgroundColor: "#fff",
    },
    centerWrapper: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 40,
      paddingBottom: 0,
    },
    childrenContainer: {
      flex: 1,
      maxWidth: 1200,
      width: "100%",
      marginHorizontal: "auto",
    },
    mobileChildrenContainer: {
      flex: 1,
      paddingHorizontal: 20,
    },
    chatBarContainer: {
      width: "100%",
      maxWidth: 1250,
      alignSelf: "center",
      zIndex: 999,
    },
    mobileChatBarContainer: {
      marginBottom: 20,
      marginHorizontal: 10,
    },
    mobileMenuContainer: {
      zIndex: 9999,
      position: "absolute",
      bottom: 0,
      top: 85,
      right: 0,
      left: 0,
    },
    chatWindowContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 900,
      display: "flex",
      flexDirection: "row",
      backgroundColor: "transparent",
    },
  });

export default DefaultLayout;
