import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { usePostHog } from "posthog-js/react";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { MotiView } from "moti";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import LessonHeader from "../components/learning/lesson/LessonHeader/LessonHeader";
import { useAppConfig } from "../AppConfigProvider";
import LessonObjectives from "../components/learning/lesson/LessonObjectives/LessonObjectives";
import { useSubchapterByIdQuery, useGetFirstSubchapterByTopicNameQuery } from "../graphql/generated/graphql";
import { ScrollView } from "react-native-gesture-handler";
import TrackLoader from "../components/common/status/TrackLoader/TrackLoader";
import DefaultLayout from "../layouts/DefaultLayout.web";

function LessonSubchapterView() {
  const { isMedium } = useResponsiveScreen();
  const posthog = usePostHog();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;
  const navigate = useNavigation();

  const route = useRoute();
  const { subchapter, topic } = route.params;

  const {
    data: topicData,
    loading: byTopicLoading,
    error: byTopicError,
  } = useGetFirstSubchapterByTopicNameQuery({
    variables: { topicName: topic },
    skip: subchapter !== null && subchapter !== undefined,
  });

  const activeSubchapterName = subchapter ?? topicData?.getFirstSubchapterByTopicName?.name;

  const {
    data,
    loading: subChapterByIdLoading,
    error: subChapterByIdError,
  } = useSubchapterByIdQuery({
    variables: {
      name: activeSubchapterName,
    },
    skip: subchapter == null || subchapter == undefined,
  });

  const activeSubchapterDes =
    data?.getSubchapterById?.description ?? topicData?.getFirstSubchapterByTopicName?.description;

  const activeSubchapterObjectives =
    data?.getSubchapterById?.objectives ?? topicData?.getFirstSubchapterByTopicName?.objectives ?? [];

  useEffect(() => {
    posthog?.capture("Lesson subchapter page visited");
  }, []);

  const loading = byTopicLoading || subChapterByIdLoading;
  const error = byTopicError || subChapterByIdError;

  const handleStartButtonClick = () => {
    if (activeSubchapterObjectives.length === 0) {
      return;
    }

    const nextLearningObjective = activeSubchapterObjectives.filter((lo) => lo.completed !== 1)[0];

    navigate.navigate("Lesson Activities", {
      ...route.params,
      topic: topic,
      subchapter: activeSubchapterName,
      loTitle: nextLearningObjective.title,
      loId: nextLearningObjective.id,
    });
  };

  return (
    <DefaultLayout showLearnMenu={true}>
      {(loading || error) && (
        <View style={styles.loaderContainer}>
          <TrackLoader />
        </View>
      )}

      {!loading && !error && (
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={10}
            transition={{ type: "timing", duration: 500 }}>
            <CustomSpacing type="vertical" size="s" />
            <LessonHeader title={activeSubchapterName} tag="Unit" description={activeSubchapterDes} />
            <CustomSpacing type="vertical" size="xl" />
          </MotiView>
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={300}
            transition={{ type: "timing", duration: 400 }}>
            <View style={[styles.body, { flexDirection: isMedium ? "column" : "column-reverse" }]}>
              {activeSubchapterObjectives.length > 0 && <LessonObjectives objectives={activeSubchapterObjectives} />}
              <CustomSpacing type="vertical" size="l" />
              <View style={styles.bodyColumn}></View>
            </View>
          </MotiView>

          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={600}
            transition={{ type: "timing", duration: 600 }}>
            <CustomSpacing type="vertical" size={isMedium ? "l" : "xl"} />
            <View style={styles.bottomRow}>
              <CustomButton
                text="Start Next Activity"
                bold
                style={[styles.unitButton, { border: `2px solid ${primaryColor}` }]}
                textStyle={{ fontSize: 14 }}
                styleType="primaryLight"
                onPress={handleStartButtonClick}
              />
            </View>
          </MotiView>
        </ScrollView>
      )}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  unitButton: {
    padding: 12,
  },
  scrollView: {
    height: 20,
  },
  bottomRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  body: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  bodyColumn: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    textAlign: "left",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default LessonSubchapterView;
