import { useAppState, actions } from "../../../contexts/AppStateContext";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import getLearningRoute from "../../../utils/getLearningRoute";
import LearnMenu from "../../../components/navigation/LearnMenu/LearnMenu";
import {
  useTableOfContentsQuery,
  useSetKnowledgeRelevanceMutation,
  TableOfContentsDocument,
  NodeGraphDocument,
  useUserProgressQuery,
  GetFirstSubchapterByTopicNameDocument,
  SubchapterByIdDocument,
  NodeLabel,
} from "../../../graphql/generated/graphql";
import { useEffect, useMemo, useState } from "react";
import HideLessonConfirmationModal from "../../../components/navigation/HideLessonConfirmationModal/HideLessonConfirmationModal";
import { apolloClient } from "../../../graphql/graphql.client";
import { useAccessibilityContext } from "../../../contexts/AccessibilityContext";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../../navigation/AppNavigator.web";

function LearnMenuContainer() {
  const { state, dispatch } = useAppState();
  const navigate = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const route = useRoute<RouteProp<RootStackParamList, "Lesson Activities">>();
  const params = route.params;
  const { ancestors } = getLearningRoute(params);
  const { screenReaderIsEnabled } = useAccessibilityContext();

  const fieldName = useMemo(() => ancestors[0], [ancestors]);
  const subfieldName = useMemo(() => ancestors[1], [ancestors]);
  const topicName = params.topic;

  const [showHideModal, setShowHideModal] = useState(false);

  const [expandedChapter, setExpandedChapter] = useState(params.chapter);
  const [expandedSubchapter, setExpandedSubchapter] = useState(params.subchapter);
  const activeUnit = useMemo(() => params.loId, [params]); // loId
  const [hoveredElement, setHoveredElement] = useState(null);
  const [elementToUpdate, setElementToUpdate] = useState(null);

  useEffect(() => {
    if (params.chapter) {
      setExpandedChapter(params.chapter);
    }

    if (params.subchapter) {
      setExpandedSubchapter(params.subchapter);
    }
  }, [params.chapter, params.subchapter]);

  const modalText = useMemo(() => {
    const label = elementToUpdate?.nodeLabel === "Chapter" ? "Lesson" : "Unit";

    return {
      title: `Hide ${label}`,
      text: `This ${label} will be hidden from your view but can be unhidden at any time by clicking on the closed eye icon next to the ${label} name. This won't impact your track completion progress.`,
    };
  }, [elementToUpdate?.nodeLabel]);

  const {
    error: chaptersError,
    data: chapters,
    refetch: chaptersRefetch,
  } = useTableOfContentsQuery({
    variables: {
      learningNodeNames: [topicName],
      hierarchyLabel: "Topic",
    },
  });

  const {
    error: subchaptersError,
    data: subchapters,
    refetch: subchaptersRefetch,
  } = useTableOfContentsQuery({
    variables: {
      learningNodeNames: chapters?.getTableOfContents?.map((c) => c.name),
      hierarchyLabel: "Chapter",
    },
    skip: !chapters,
    onCompleted: () => {
      apolloClient.refetchQueries({
        include: [SubchapterByIdDocument],
      });
    },
  });

  const {
    error: objectivesError,
    data: objectives,
    refetch: objectivesRefetch,
  } = useTableOfContentsQuery({
    variables: {
      learningNodeNames: subchapters?.getTableOfContents?.map((c) => c.name),
      hierarchyLabel: "Subchapter",
    },
    skip: !subchapters,
    onCompleted: () => {
      apolloClient.refetchQueries({
        include: [SubchapterByIdDocument, GetFirstSubchapterByTopicNameDocument],
      });
    },
  });

  const { data: progressData, error: progressError } = useUserProgressQuery({
    variables: { nodeName: subfieldName, nodeLabel: NodeLabel.Subfield },
  });

  useEffect(() => {
    if (state.content?.type === "FINAL") {
      chaptersRefetch();
      subchaptersRefetch();
      objectivesRefetch();
    }
  }, [state.content?.type]);

  useEffect(() => {
    if ((expandedChapter && expandedSubchapter) || activeUnit) {
      return;
    }
    if (chapters?.getTableOfContents?.length > 0) {
      const firstNotCompletedChapter = chapters?.getTableOfContents?.find((c) => c.mastery !== 100);
      const firstNotCompletedSubchapter = subchapters?.getTableOfContents?.find((s) => s.mastery !== 100);

      if (firstNotCompletedChapter && firstNotCompletedSubchapter) {
        setExpandedChapter(firstNotCompletedChapter.name);
        setExpandedSubchapter(firstNotCompletedSubchapter.name);
      }
    }
  }, [chapters, subchapters]);

  const completedChapters = useMemo(
    () => chapters?.getTableOfContents?.filter((c) => c.mastery === 100)?.length,
    [chapters]
  );

  const error = progressError || chaptersError || subchaptersError || objectivesError;

  const data = !error && {
    field: fieldName,
    subfield: subfieldName,
    chapters: chapters?.getTableOfContents,
    subchapters: subchapters?.getTableOfContents,
    objectives: objectives?.getTableOfContents,
    completedChapters: completedChapters,
    mastery: progressData?.getUserProgress?.find((progress) => progress.child.name === topicName)?.totalMastery,
  };

  const [isNotRelevant] = useSetKnowledgeRelevanceMutation({
    refetchQueries: [
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: [topicName],
          hierarchyLabel: "Topic",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: chapters?.getTableOfContents.map((c) => c.name),
          hierarchyLabel: "Chapter",
        },
      },
      {
        query: TableOfContentsDocument,
        variables: {
          learningNodeNames: subchapters?.getTableOfContents.map((s) => s.name),
          hierarchyLabel: "Subchapter",
        },
      },
      {
        query: NodeGraphDocument,
        variables: { nodeLabel: "Topic", nodeName: topicName },
      },
    ],
  });

  const updateRelevance = async (newRelevanceState, topicName, label) => {
    const { nodeName, nodeLabel } = elementToUpdate;

    await isNotRelevant({
      variables: {
        nodeName: topicName ?? nodeName,
        nodeLabel: label ?? nodeLabel,
        isRelevant: newRelevanceState,
      },
    });

    setShowHideModal(false);
  };

  const handleLearningObjectivePress = (subchapterTitle, chapterTitle, loId, loTitle, isAssessment) => {
    handleNavigate();
    if (isAssessment) {
      navigate.replace("Lesson Assessment", {
        field: fieldName,
        subfield: subfieldName,
        topic: params.topic,
        chapter: chapterTitle,
        subchapter: subchapterTitle,
        loTitle: loTitle,
        loId: loId,
      });
    } else {
      navigate.replace("Lesson Activities", {
        field: fieldName,
        subfield: subfieldName,
        topic: params.topic,
        chapter: chapterTitle,
        subchapter: subchapterTitle,
        loTitle: loTitle,
        loId: loId,
      });
    }
  };

  const handleSubchapterPress = (title, chapter) => {
    handleNavigate();
    setExpandedSubchapter(title);
    navigate.replace("Lesson", {
      field: fieldName,
      subfield: subfieldName,
      topic: params.topic,
      chapter: chapter,
      subchapter: title,
    });
  };

  const handleShowHidePress = async ({ nodeName, nodeLabel, isRelevant }) => {
    setElementToUpdate({ nodeName, nodeLabel, isRelevant });

    if (isRelevant === true) {
      setShowHideModal(true);
      return;
    }

    await updateRelevance(true, nodeName, nodeLabel);
  };

  const handleExitPress = () => {
    navigate.replace("Tracks", {
      field: fieldName,
      subfield: subfieldName,
    });
    handleNavigate();
  };

  const handleNavigate = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isLearnMenuOpen: false,
      },
    });
  };

  return (
    <>
      <HideLessonConfirmationModal
        hideModalState={[showHideModal, setShowHideModal]}
        modalText={modalText}
        updateRelevance={updateRelevance}
      />
      <LearnMenu
        data={data}
        topicName={topicName}
        isError={error}
        onExitPress={handleExitPress}
        onShowHidePress={handleShowHidePress}
        expandedChapterState={[expandedChapter, setExpandedChapter]}
        expandedSubchapterState={[expandedSubchapter, setExpandedSubchapter]}
        activeUnit={activeUnit}
        hoveredElementState={[hoveredElement, setHoveredElement]}
        onSubchapterPress={handleSubchapterPress}
        onLearningObjectivePress={handleLearningObjectivePress}
        screenReaderIsEnabled={screenReaderIsEnabled}
      />
    </>
  );
}

export default LearnMenuContainer;
