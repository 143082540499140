import React from "react";
import { View, StyleSheet } from "react-native";
import TaskList from "../TaskList/TaskList";
import Accordion from "../Accordion/Accordion";
import { sortTasks } from "../../../utils/array";

function AccordionList({ tasks, onCheckboxToggle, onJourneyPathPress, onTaskPress, isJourneyView }) {
  const isToday = (task) => {
    const today = new Date();
    const dueDate = new Date(task.date);

    return dueDate.toDateString() === today.toDateString();
  };

  const isWeek = (task) => {
    const today = new Date();
    const weekFromToday = new Date();
    const dueDate = new Date(task.date);
    weekFromToday.setDate(today.getDate() + 7);

    return dueDate > today && dueDate <= weekFromToday;
  };

  const isMonth = (task) => {
    const today = new Date();
    const dueDate = new Date(task.date);
    const weekFromToday = new Date();
    weekFromToday.setDate(today.getDate() + 7);
    return (
      dueDate > weekFromToday &&
      dueDate.getMonth() === today.getMonth() &&
      dueDate.getFullYear() === today.getFullYear()
    );
  };

  const isPast = (task) => {
    const today = new Date();
    const dueDate = new Date(task.date);
    today.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);
    return dueDate < today;
  };

  const todayTasks = sortTasks(tasks.filter((task) => isToday(task)));
  const weekTasks = sortTasks(tasks.filter((task) => isWeek(task) && !isToday(task)));
  const monthTasks = sortTasks(tasks.filter((task) => isMonth(task) && !isWeek(task) && !isToday(task)));
  const laterTasks = sortTasks(
    tasks.filter((task) => new Date(task.date) > new Date() && !isMonth(task) && !isWeek(task) && !isToday(task))
  );

  const overdueTasks = sortTasks(tasks.filter((task) => isPast(task)));

  return (
    <View style={styles.container}>
      {todayTasks.length > 0 && (
        <Accordion title="Today">
          <View style={styles.tasklist}>
            <TaskList
              tasks={todayTasks}
              onCheckboxToggle={onCheckboxToggle}
              onJourneyPathPress={onJourneyPathPress}
              onTaskPress={onTaskPress}
              isJourneyView={isJourneyView}
            />
          </View>
        </Accordion>
      )}
      {weekTasks.length > 0 && (
        <Accordion title="This Week">
          <View style={styles.tasklist}>
            <TaskList
              tasks={weekTasks}
              onCheckboxToggle={onCheckboxToggle}
              onJourneyPathPress={onJourneyPathPress}
              onTaskPress={onTaskPress}
              isJourneyView={isJourneyView}
            />
          </View>
        </Accordion>
      )}
      {monthTasks.length > 0 && (
        <Accordion title="This Month">
          <View style={styles.tasklist}>
            <TaskList
              tasks={monthTasks}
              onCheckboxToggle={onCheckboxToggle}
              onJourneyPathPress={onJourneyPathPress}
              onTaskPress={onTaskPress}
              isJourneyView={isJourneyView}
            />
          </View>
        </Accordion>
      )}
      {laterTasks.length > 0 && (
        <Accordion title="Later">
          <View style={styles.lastTaskList}>
            <TaskList
              tasks={laterTasks}
              onCheckboxToggle={onCheckboxToggle}
              onJourneyPathPress={onJourneyPathPress}
              onTaskPress={onTaskPress}
              isJourneyView={isJourneyView}
            />
          </View>
        </Accordion>
      )}
      {overdueTasks.length > 0 && (
        <Accordion title="Overdue">
          <View style={styles.lastTaskList}>
            <TaskList
              tasks={overdueTasks}
              onCheckboxToggle={onCheckboxToggle}
              onJourneyPathPress={onJourneyPathPress}
              onTaskPress={onTaskPress}
              isJourneyView={isJourneyView}
            />
          </View>
        </Accordion>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    paddingHorizontal: 0,
    paddingTop: 10,
  },
  tasklist: {
    marginTop: -10,
    marginBottom: 0,
    paddingHorizontal: 0,
  },
  lastTaskList: {
    marginTop: -10,
    paddingHorizontal: 0,
  },
});

export default AccordionList;
