import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type Achievement = {
  __typename?: "Achievement";
  category?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ActiveTrack = {
  __typename?: "ActiveTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type AspectDetailsInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsType = {
  __typename?: "AspectDetailsType";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  communicationChannel?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  connectionStrength?: Maybe<Scalars["Int"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  deadline?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  frequencyOfInteraction?: Maybe<Scalars["String"]["output"]>;
  friendId?: Maybe<Scalars["Int"]["output"]>;
  friendPersonalityTraits?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  lastInteractionDate?: Maybe<Scalars["Date"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  relationshipType?: Maybe<Scalars["String"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sharedEventsAttended?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedGoals?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedInterests?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Date"]["output"]>;
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type AssignedQuest = {
  __typename?: "AssignedQuest";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isComplete: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export type AuthPayload = {
  __typename?: "AuthPayload";
  token: Scalars["String"]["output"];
  user?: Maybe<User>;
};

export type Career = {
  __typename?: "Career";
  brief_description?: Maybe<Scalars["String"]["output"]>;
  cons?: Maybe<Scalars["String"]["output"]>;
  core_skills?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  growth?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  name: Scalars["String"]["output"];
  path?: Maybe<Scalars["JSON"]["output"]>;
  pros?: Maybe<Scalars["String"]["output"]>;
  salary?: Maybe<Scalars["String"]["output"]>;
  skills?: Maybe<Scalars["String"]["output"]>;
  trends?: Maybe<Scalars["JSON"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CareerHeader = {
  __typename?: "CareerHeader";
  careerGoal?: Maybe<Scalars["String"]["output"]>;
  careerId?: Maybe<Scalars["String"]["output"]>;
  careerName?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
};

export type CareerTopicResponse = {
  __typename?: "CareerTopicResponse";
  careerName: Scalars["String"]["output"];
  relationshipIndex: Scalars["Int"]["output"];
  topicName: Scalars["String"]["output"];
};

export type Challenge = {
  __typename?: "Challenge";
  category?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type Chapter = {
  __typename?: "Chapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type Chat = {
  __typename?: "Chat";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  context?: Maybe<Scalars["String"]["output"]>;
  conversation: Array<MessageType>;
  date?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  objectId?: Maybe<Scalars["ID"]["output"]>;
  params?: Maybe<Scalars["JSON"]["output"]>;
  timezone: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["ID"]["output"];
};

export type ChildInfo = {
  __typename?: "ChildInfo";
  completedTracks?: Maybe<Scalars["Float"]["output"]>;
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  totalTracks?: Maybe<Scalars["Float"]["output"]>;
};

export type CoreValue = {
  __typename?: "CoreValue";
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type DailyCompletedTodosCount = {
  __typename?: "DailyCompletedTodosCount";
  completedTodosCount: Scalars["Int"]["output"];
};

export type DailyExerciseTotals = {
  __typename?: "DailyExerciseTotals";
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  workoutsList?: Maybe<Array<Maybe<Workout>>>;
};

export type DailyNutritionTotals = {
  __typename?: "DailyNutritionTotals";
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
};

export type DailySummary = {
  __typename?: "DailySummary";
  date: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
};

export type EmotionalState = {
  __typename?: "EmotionalState";
  id?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Date"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
};

export type Event = {
  __typename?: "Event";
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ExportCsvResponse = {
  __typename?: "ExportCsvResponse";
  csvData?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type Feedback = {
  __typename?: "Feedback";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type Field = {
  __typename?: "Field";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type Food = {
  __typename?: "Food";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
};

export type Game = {
  __typename?: "Game";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  chatId?: Maybe<Scalars["ID"]["output"]>;
  dailyCalorieCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyCalorieGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  refreshCounter?: Maybe<Scalars["Int"]["output"]>;
};

export type Generated = {
  __typename?: "Generated";
  data?: Maybe<Array<Maybe<Subfield>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type Goal = {
  __typename?: "Goal";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  deadline?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type GoogleAuthTokens = {
  __typename?: "GoogleAuthTokens";
  access_token?: Maybe<Scalars["String"]["output"]>;
  expiry_date?: Maybe<Scalars["Float"]["output"]>;
  refresh_token?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
  token_type?: Maybe<Scalars["String"]["output"]>;
};

export type Hierarchy = {
  __typename?: "Hierarchy";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  isRelevant: Scalars["Boolean"]["output"];
  mastery: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  parentName: Scalars["String"]["output"];
};

export type HierarchyNode = {
  __typename?: "HierarchyNode";
  label: Scalars["String"]["output"];
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type Hobby = {
  __typename?: "Hobby";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type Interest = {
  __typename?: "Interest";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type InviteCount = {
  __typename?: "InviteCount";
  count: Scalars["Int"]["output"];
};

export type Journey = {
  __typename?: "Journey";
  completed: Scalars["Boolean"]["output"];
  completedTasks?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  name: Scalars["String"]["output"];
  tasks?: Maybe<Array<Maybe<Todo>>>;
  totalTasks?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type LearningNode = {
  __typename?: "LearningNode";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  unitsCount?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningObjective = {
  __typename?: "LearningObjective";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  index?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  total_mastery?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningObjectiveResponse = {
  __typename?: "LearningObjectiveResponse";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LearningSummary = {
  __typename?: "LearningSummary";
  subchaptersProgressed?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningTree = {
  __typename?: "LearningTree";
  ChapterName?: Maybe<Scalars["String"]["output"]>;
  FieldName?: Maybe<Scalars["String"]["output"]>;
  SubchapterName?: Maybe<Scalars["String"]["output"]>;
  SubfieldName?: Maybe<Scalars["String"]["output"]>;
  TopicName?: Maybe<Scalars["String"]["output"]>;
};

export type Lesson = {
  __typename?: "Lesson";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type LevelDetails = {
  __typename?: "LevelDetails";
  currentXP: Scalars["Int"]["output"];
  level: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  progressPercentage: Scalars["Int"]["output"];
  startingXPForCurrentLevel: Scalars["Int"]["output"];
  xpToNextLevel: Scalars["Int"]["output"];
};

export type LifeAspiration = {
  __typename?: "LifeAspiration";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type MasteryResult = {
  __typename?: "MasteryResult";
  learningObjectiveId?: Maybe<Scalars["ID"]["output"]>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export type MessageInput = {
  ai?: InputMaybe<Scalars["String"]["input"]>;
  aspect?: InputMaybe<SnackInput>;
  human?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  loader?: InputMaybe<SnackInput>;
  quest?: InputMaybe<SnackInput>;
  snack?: InputMaybe<SnackInput>;
};

export type MessageType = {
  __typename?: "MessageType";
  aspectDetails?: Maybe<AspectDetailsType>;
  content?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  nodeId?: Maybe<Scalars["String"]["output"]>;
  parameters?: Maybe<ParametersType>;
  sender?: Maybe<Scalars["String"]["output"]>;
  snackType?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  addFood?: Maybe<Food>;
  addNutrition?: Maybe<Nutrition>;
  addToWhitelist: WhitelistedEmail;
  addTodo?: Maybe<Todo>;
  audioToggleUser?: Maybe<User>;
  careerIsLoading: UserCareerLoadingResponse;
  careerSubmitForm: Scalars["Boolean"]["output"];
  careerTopicRelationship: CareerTopicResponse;
  completeJourney?: Maybe<ResponseType>;
  createEducationChildren: Generated;
  createFeedback?: Maybe<Feedback>;
  createGoal?: Maybe<Goal>;
  createInterest?: Maybe<Interest>;
  createLesson?: Maybe<Lesson>;
  createPreference?: Maybe<Preferences>;
  createProgress?: Maybe<Progress>;
  createSocialConnection?: Maybe<SocialConnection>;
  createTodayDailyGoals: ResponseType;
  deleteAccount?: Maybe<ResponseType>;
  deleteAspect: ResponseType;
  deleteFood?: Maybe<Food>;
  deleteGoal?: Maybe<Scalars["Boolean"]["output"]>;
  deleteInterest?: Maybe<Scalars["Boolean"]["output"]>;
  deleteJourney?: Maybe<ResponseType>;
  deleteLesson?: Maybe<Scalars["Boolean"]["output"]>;
  deleteLoaderMessages: Scalars["Boolean"]["output"];
  deleteMessage?: Maybe<MessageType>;
  deletePreference?: Maybe<Scalars["Boolean"]["output"]>;
  deleteProgress?: Maybe<Scalars["Boolean"]["output"]>;
  deleteSocialConnection?: Maybe<Scalars["Boolean"]["output"]>;
  deleteTodo?: Maybe<ResponseType>;
  deleteUser?: Maybe<Scalars["Boolean"]["output"]>;
  enrollUserInTrack: ResponseType;
  generateSuggestedCareers?: Maybe<Career>;
  generateYoutubeKeywords?: Maybe<Scalars["String"]["output"]>;
  journeySubmitForm?: Maybe<ResponseType>;
  login?: Maybe<AuthPayload>;
  logout: ResponseType;
  markQuestComplete?: Maybe<Chat>;
  onboardUser?: Maybe<User>;
  refreshQuest?: Maybe<RefreshQuestResponse>;
  removeFromWhitelist: Scalars["Boolean"]["output"];
  resetAccount?: Maybe<ResponseType>;
  resetPassword?: Maybe<ResponseType>;
  setArchiveStatus: ResponseType;
  setCareerPathActive: Scalars["Boolean"]["output"];
  setKnowledgeRelevance: ResponseType;
  setOnboardingStatus: ResponseType;
  signup?: Maybe<AuthPayload>;
  updateChat: Chat;
  updateFeedback?: Maybe<Feedback>;
  updateFood?: Maybe<Food>;
  updateGoal?: Maybe<Goal>;
  updateInterest?: Maybe<Interest>;
  updateLearningObjectiveCompletion: ResponseType;
  updateLesson?: Maybe<Lesson>;
  updateNotifications: ResponseType;
  updatePreference?: Maybe<Preferences>;
  updateProgress?: Maybe<Progress>;
  updateSocialConnection?: Maybe<SocialConnection>;
  updateTodo?: Maybe<Todo>;
  updateTopic: ResponseType;
  updateUserModalOnboarding?: Maybe<User>;
};

export type MutationAddFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nutritionId?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationAddNutritionArgs = {
  date: Scalars["String"]["input"];
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationAddToWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationAddTodoArgs = {
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAudioToggleUserArgs = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationCareerIsLoadingArgs = {
  isLoading: Scalars["Boolean"]["input"];
};

export type MutationCareerSubmitFormArgs = {
  careerGoal: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  currentOccupation: Scalars["String"]["input"];
};

export type MutationCareerTopicRelationshipArgs = {
  careerName: Scalars["String"]["input"];
  index: Scalars["Int"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationCompleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationCreateEducationChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type MutationCreateFeedbackArgs = {
  context: Scalars["String"]["input"];
  date: Scalars["Date"]["input"];
  sentiment: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateGoalArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  deadline: Scalars["Date"]["input"];
  name: Scalars["String"]["input"];
  priority: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateInterestArgs = {
  category: Scalars["String"]["input"];
  lastUpdated: Scalars["Date"]["input"];
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  user: UserInput;
};

export type MutationCreateLessonArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  difficultyLevel: Scalars["String"]["input"];
  endDate: Scalars["Date"]["input"];
  format: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  user: UserInput;
};

export type MutationCreatePreferenceArgs = {
  contentType: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  learningDuration: Scalars["Int"]["input"];
  learningStyle: Scalars["String"]["input"];
  notificationPreferences: Scalars["String"]["input"];
  timeOfDay: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateProgressArgs = {
  date: Scalars["Date"]["input"];
  description: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateSocialConnectionArgs = {
  communicationChannel: Scalars["String"]["input"];
  connectionStrength: Scalars["Int"]["input"];
  frequencyOfInteraction: Scalars["String"]["input"];
  friendId: Scalars["Int"]["input"];
  friendPersonalityTraits: Array<InputMaybe<Scalars["String"]["input"]>>;
  lastInteractionDate: Scalars["Date"]["input"];
  relationshipType: Scalars["String"]["input"];
  sharedEventsAttended: Array<InputMaybe<Scalars["String"]["input"]>>;
  sharedGoals: Array<InputMaybe<Scalars["String"]["input"]>>;
  sharedInterests: Array<InputMaybe<Scalars["String"]["input"]>>;
  user: UserInput;
};

export type MutationCreateTodayDailyGoalsArgs = {
  timezone: Scalars["String"]["input"];
};

export type MutationDeleteAspectArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type MutationDeleteFoodArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteGoalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInterestArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationDeleteLessonArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteMessageArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeletePreferenceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteProgressArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSocialConnectionArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteTodoArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationEnrollUserInTrackArgs = {
  topicName: Scalars["String"]["input"];
};

export type MutationGenerateYoutubeKeywordsArgs = {
  description: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type MutationJourneySubmitFormArgs = {
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationMarkQuestCompleteArgs = {
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
};

export type MutationOnboardUserArgs = {
  is_onboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationRefreshQuestArgs = {
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationRemoveFromWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationSetArchiveStatusArgs = {
  isArchived: Scalars["Boolean"]["input"];
  nodeId: Scalars["ID"]["input"];
};

export type MutationSetCareerPathActiveArgs = {
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSetKnowledgeRelevanceArgs = {
  isRelevant: Scalars["Boolean"]["input"];
  nodeLabel: Scalars["String"]["input"];
  nodeName: Scalars["String"]["input"];
};

export type MutationSetOnboardingStatusArgs = {
  isOnboarded: Scalars["Boolean"]["input"];
};

export type MutationSignupArgs = {
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationUpdateChatArgs = {
  chatId: Scalars["String"]["input"];
  messageId: Scalars["String"]["input"];
  newContent: Scalars["String"]["input"];
};

export type MutationUpdateFeedbackArgs = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateGoalArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateInterestArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  user: UserInput;
};

export type MutationUpdateLearningObjectiveCompletionArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationUpdateLessonArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  user: UserInput;
};

export type MutationUpdateNotificationsArgs = {
  dailyEmailOptIn: Scalars["Boolean"]["input"];
};

export type MutationUpdatePreferenceArgs = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  language?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateProgressArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateSocialConnectionArgs = {
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id: Scalars["ID"]["input"];
  lastInteractionDate?: InputMaybe<Scalars["Date"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  user: UserInput;
};

export type MutationUpdateTodoArgs = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type MutationUpdateTopicArgs = {
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationUpdateUserModalOnboardingArgs = {
  modal_onboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type Node = {
  __typename?: "Node";
  identity: NodeIdentity;
  labels: Array<Scalars["String"]["output"]>;
  properties: NodeProperties;
};

export type NodeIdentity = {
  __typename?: "NodeIdentity";
  high: Scalars["Int"]["output"];
  low: Scalars["Int"]["output"];
};

export enum NodeLabel {
  Chapter = "Chapter",
  Field = "Field",
  Subchapter = "Subchapter",
  Subfield = "Subfield",
  Topic = "Topic",
}

export type NodeProperties = {
  __typename?: "NodeProperties";
  name: Scalars["String"]["output"];
};

export type NodeRelation = {
  __typename?: "NodeRelation";
  _fields: Array<Node>;
};

export type Nutrition = {
  __typename?: "Nutrition";
  date: Scalars["String"]["output"];
  foods?: Maybe<Array<Maybe<Food>>>;
  id: Scalars["ID"]["output"];
  water_intake_ml?: Maybe<Scalars["Int"]["output"]>;
};

export type ParametersInput = {
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersType = {
  __typename?: "ParametersType";
  lastGeneratedLabel?: Maybe<Scalars["String"]["output"]>;
  lastGeneratedTopic?: Maybe<Scalars["String"]["output"]>;
  questContext?: Maybe<Scalars["String"]["output"]>;
  userResponse?: Maybe<Scalars["String"]["output"]>;
};

export type PersonalityTrait = {
  __typename?: "PersonalityTrait";
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type Preferences = {
  __typename?: "Preferences";
  contentType?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
};

export type Prerequisite = {
  __typename?: "Prerequisite";
  field?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subfield?: Maybe<Scalars["String"]["output"]>;
};

export type Progress = {
  __typename?: "Progress";
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  exportCsv: ExportCsvResponse;
  fetchUserContext?: Maybe<UserContext>;
  foods?: Maybe<Array<Maybe<Food>>>;
  getActiveTracks?: Maybe<Array<Maybe<ActiveTrack>>>;
  getCareerById?: Maybe<Career>;
  getCareerHeader: CareerHeader;
  getCareerTopics?: Maybe<Array<Maybe<Topic>>>;
  getCareers?: Maybe<Array<Maybe<Career>>>;
  getChapters?: Maybe<Array<Maybe<Subfield>>>;
  getChatByContextAndParams?: Maybe<Chat>;
  getChatById?: Maybe<Chat>;
  getChatByObjectId?: Maybe<Chat>;
  getChatForDate?: Maybe<Chat>;
  getChats?: Maybe<Array<Maybe<Chat>>>;
  getDailyCompletedTodos?: Maybe<DailyCompletedTodosCount>;
  getDailyExerciseTotalsByDate?: Maybe<DailyExerciseTotals>;
  getDailyLimit?: Maybe<RateLimitResult>;
  getDailyNutritionTotalsByDate?: Maybe<DailyNutritionTotals>;
  getFields: Array<Maybe<Field>>;
  getFirstSubchapterByTopicName: SubchapterDetails;
  getFoodsByDate?: Maybe<Array<Maybe<Food>>>;
  getJourneyById?: Maybe<Journey>;
  getJourneys?: Maybe<Array<Maybe<Journey>>>;
  getLearnedSubchaptersByDate?: Maybe<LearningSummary>;
  getLearningObjectiveById: LearningObjectiveResponse;
  getLearningObjectivesBySubchapter?: Maybe<Array<Maybe<LearningObjective>>>;
  getLearningTree?: Maybe<LearningTree>;
  getLevelDetails?: Maybe<LevelDetails>;
  getNode?: Maybe<LearningNode>;
  getNodeChildren: Array<Maybe<LearningNode>>;
  getNodeParents: Array<Maybe<LearningNode>>;
  getRecentTracks?: Maybe<Array<Maybe<RecentTrack>>>;
  getSchools?: Maybe<Array<Maybe<School>>>;
  getSiblingSubchapters?: Maybe<Array<Maybe<HierarchyNode>>>;
  getSubchapterById?: Maybe<SubchapterDetails>;
  getSubchapterPrerequisites?: Maybe<Array<Maybe<HierarchyNode>>>;
  getSubchapters?: Maybe<Array<Maybe<Subfield>>>;
  getSubfields?: Maybe<Array<Maybe<Subfield>>>;
  getSuggestions?: Maybe<Array<Maybe<Suggestion>>>;
  getTableOfContents?: Maybe<Array<Maybe<Hierarchy>>>;
  getTodayChat?: Maybe<Chat>;
  getTodayGame?: Maybe<Game>;
  getTopics?: Maybe<Array<Maybe<Subfield>>>;
  getTrackOverview?: Maybe<TrackOverview>;
  getTransactionHistory?: Maybe<Array<Maybe<Transaction>>>;
  getTransactionHistoryForDate: Array<TransactionByDate>;
  getUserDailySummaries?: Maybe<Array<Maybe<DailySummary>>>;
  getUserJournalEntry?: Maybe<DailySummary>;
  getUserLearningObjectiveMastery?: Maybe<MasteryResult>;
  getUserMastery: Array<UserMastery>;
  getUserOverallProgress: Array<UserProgress>;
  getUserProgress: Array<UserProgress>;
  getWaterIntakeByDate?: Maybe<WaterIntakeSummary>;
  isEmailWhitelisted: Scalars["Boolean"]["output"];
  me?: Maybe<User>;
  nutrition?: Maybe<Array<Maybe<Nutrition>>>;
  nutritionByDate?: Maybe<Array<Maybe<Nutrition>>>;
  recentTodos?: Maybe<Array<Maybe<Todo>>>;
  todos?: Maybe<Array<Maybe<Todo>>>;
  userInviteCount: InviteCount;
  validateSession: Session;
};

export type QueryFetchUserContextArgs = {
  userId: Scalars["ID"]["input"];
};

export type QueryFoodsArgs = {
  nutritionId: Scalars["String"]["input"];
};

export type QueryGetCareerByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCareerTopicsArgs = {
  careerId: Scalars["ID"]["input"];
};

export type QueryGetChaptersArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetChatByContextAndParamsArgs = {
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type QueryGetChatByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetChatByObjectIdArgs = {
  context: Scalars["String"]["input"];
  objectId: Scalars["ID"]["input"];
};

export type QueryGetChatForDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyCompletedTodosArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyExerciseTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyNutritionTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetFirstSubchapterByTopicNameArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetFoodsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetJourneyByIdArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type QueryGetLearnedSubchaptersByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetLearningObjectiveByIdArgs = {
  loId: Scalars["ID"]["input"];
};

export type QueryGetLearningObjectivesBySubchapterArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetLearningTreeArgs = {
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryGetNodeArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeParentsArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetRecentTracksArgs = {
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSiblingSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetSubchapterByIdArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetSubchapterPrerequisitesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
};

export type QueryGetSubfieldsArgs = {
  fieldName: Scalars["String"]["input"];
};

export type QueryGetTableOfContentsArgs = {
  hierarchyLabel: Scalars["String"]["input"];
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetTodayChatArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTodayGameArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTopicsArgs = {
  subfieldName: Scalars["String"]["input"];
};

export type QueryGetTrackOverviewArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetTransactionHistoryArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetTransactionHistoryForDateArgs = {
  date: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserDailySummariesArgs = {
  timeRange: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserJournalEntryArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserLearningObjectiveMasteryArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetUserMasteryArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserProgressArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
};

export type QueryGetWaterIntakeByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryIsEmailWhitelistedArgs = {
  email: Scalars["String"]["input"];
};

export type QueryNutritionByDateArgs = {
  date: Scalars["String"]["input"];
};

export type RateLimitResult = {
  __typename?: "RateLimitResult";
  dailyRequests?: Maybe<Scalars["Int"]["output"]>;
  result?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RecentCategory = {
  __typename?: "RecentCategory";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type RecentTrack = {
  __typename?: "RecentTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type Recomended = {
  __typename?: "Recomended";
  chapterName?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type Reflection = {
  __typename?: "Reflection";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type RefreshQuestResponse = {
  __typename?: "RefreshQuestResponse";
  message: Scalars["String"]["output"];
  quests: Array<AssignedQuest>;
  success: Scalars["Boolean"]["output"];
};

export type ResponseType = {
  __typename?: "ResponseType";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type School = {
  __typename?: "School";
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  providerName?: Maybe<Scalars["String"]["output"]>;
  teamName?: Maybe<Scalars["String"]["output"]>;
};

export type Session = {
  __typename?: "Session";
  isValid: Scalars["Boolean"]["output"];
};

export type SnackInput = {
  aspectDetails?: InputMaybe<AspectDetailsInput>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  parameters?: InputMaybe<ParametersInput>;
  snackType: Scalars["String"]["input"];
};

export type SocialConnection = {
  __typename?: "SocialConnection";
  communicationChannel?: Maybe<Scalars["String"]["output"]>;
  connectionStrength?: Maybe<Scalars["Int"]["output"]>;
  frequencyOfInteraction?: Maybe<Scalars["String"]["output"]>;
  friendId?: Maybe<Scalars["Int"]["output"]>;
  friendPersonalityTraits?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastInteractionDate?: Maybe<Scalars["Date"]["output"]>;
  relationshipType?: Maybe<Scalars["String"]["output"]>;
  sharedEventsAttended?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedGoals?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedInterests?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Subchapter = {
  __typename?: "Subchapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SubchapterDetails = {
  __typename?: "SubchapterDetails";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<LearningObjective>>>;
  success: Scalars["Boolean"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type Subfield = {
  __typename?: "Subfield";
  description?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type Suggestion = {
  __typename?: "Suggestion";
  assignmentId?: Maybe<Scalars["String"]["output"]>;
  classId?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Scalars["String"]["output"]>;
  integrationId?: Maybe<Scalars["String"]["output"]>;
  journeyId?: Maybe<Scalars["String"]["output"]>;
  trackName?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Todo = {
  __typename?: "Todo";
  achievements?: Maybe<Array<Maybe<Achievement>>>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  completed: Scalars["Boolean"]["output"];
  completed_date?: Maybe<Scalars["Date"]["output"]>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  feedback?: Maybe<Feedback>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  isPartOfJourney?: Maybe<Scalars["Boolean"]["output"]>;
  isRecurring?: Maybe<Scalars["Boolean"]["output"]>;
  journeyId?: Maybe<Scalars["ID"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  name: Scalars["String"]["output"];
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  pointsReceived?: Maybe<Scalars["Int"]["output"]>;
  preferences?: Maybe<Preferences>;
  priority?: Maybe<Scalars["String"]["output"]>;
  progress?: Maybe<Progress>;
  recurringDaily?: Maybe<Scalars["Boolean"]["output"]>;
  recurringDayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  recurringDayOfWeek?: Maybe<Scalars["String"]["output"]>;
  recurringFrequency?: Maybe<Scalars["String"]["output"]>;
  recurringTime?: Maybe<Scalars["String"]["output"]>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  socialConnections?: Maybe<Array<Maybe<SocialConnection>>>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  todos: Array<Todo>;
  user: User;
};

export type Topic = {
  __typename?: "Topic";
  description?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type TrackOverview = {
  __typename?: "TrackOverview";
  description?: Maybe<Scalars["String"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirementsTitle?: Maybe<Scalars["String"]["output"]>;
  prerequisites?: Maybe<Array<Maybe<Prerequisite>>>;
  skillRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type Transaction = {
  __typename?: "Transaction";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export type TransactionByDate = {
  __typename?: "TransactionByDate";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export enum TransactionType {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export type UpdateMessageInput = {
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  messageId: Scalars["ID"]["input"];
  newContent: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  achievements?: Maybe<Array<Maybe<Achievement>>>;
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  current_weight_lbs?: Maybe<Scalars["Float"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email: Scalars["String"]["output"];
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  enrolledTracks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  feedback?: Maybe<Feedback>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  goal_weight_lbs?: Maybe<Scalars["Float"]["output"]>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  googleAuthTokens?: Maybe<GoogleAuthTokens>;
  googleCalendarAuthToken?: Maybe<Scalars["String"]["output"]>;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  inviteCount?: Maybe<Scalars["Int"]["output"]>;
  isCareerLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  is_onboarded?: Maybe<Scalars["Boolean"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  modal_onboarding?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferences?: Maybe<Preferences>;
  progress?: Maybe<Progress>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  socialConnections?: Maybe<Array<Maybe<SocialConnection>>>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  todos?: Maybe<Array<Todo>>;
  trialEndDate?: Maybe<Scalars["Date"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UserCareerLoadingResponse = {
  __typename?: "UserCareerLoadingResponse";
  isCareerLoading: Scalars["Boolean"]["output"];
};

export type UserContext = {
  __typename?: "UserContext";
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  interests?: Maybe<Array<Maybe<Interest>>>;
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  preferences?: Maybe<Preferences>;
  user?: Maybe<User>;
};

export type UserInput = {
  id: Scalars["ID"]["input"];
};

export type UserMastery = {
  __typename?: "UserMastery";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type UserProgress = {
  __typename?: "UserProgress";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  parent?: Maybe<ChildInfo>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type WaterIntake = {
  __typename?: "WaterIntake";
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type WaterIntakeSummary = {
  __typename?: "WaterIntakeSummary";
  goalOz: Scalars["Float"]["output"];
  percentageTowardsGoal: Scalars["Float"]["output"];
  totalWaterIntake: Scalars["Float"]["output"];
};

export type WhitelistedEmail = {
  __typename?: "WhitelistedEmail";
  email: Scalars["String"]["output"];
};

export type Workout = {
  __typename?: "Workout";
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: {
    __typename?: "AuthPayload";
    token: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      is_onboarded?: boolean | null;
      isPro?: boolean | null;
      name: string;
      isEmailVerified?: boolean | null;
      otpPreference?: string | null;
      countryCode?: string | null;
      phoneNumber?: string | null;
      fullPhoneNumber?: string | null;
      googleAuthTokens?: {
        __typename?: "GoogleAuthTokens";
        access_token?: string | null;
        refresh_token?: string | null;
        scope?: string | null;
        token_type?: string | null;
        expiry_date?: number | null;
      } | null;
    } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: { __typename?: "ResponseType"; message?: string | null; success: boolean } | null;
};

export type SignupMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  language: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type SignupMutation = {
  __typename?: "Mutation";
  signup?: {
    __typename?: "AuthPayload";
    token: string;
    user?: {
      __typename?: "User";
      id: string;
      email: string;
      name: string;
      isPro?: boolean | null;
      is_onboarded?: boolean | null;
      otpPreference?: string | null;
      isEmailVerified?: boolean | null;
      fullPhoneNumber?: string | null;
      preferences?: { __typename?: "Preferences"; language?: string | null } | null;
    } | null;
  } | null;
};

export type CareerIsLoadingMutationVariables = Exact<{
  isLoading: Scalars["Boolean"]["input"];
}>;

export type CareerIsLoadingMutation = {
  __typename?: "Mutation";
  careerIsLoading: { __typename?: "UserCareerLoadingResponse"; isCareerLoading: boolean };
};

export type CareerSubmitFormMutationVariables = Exact<{
  currentOccupation: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  careerGoal: Scalars["String"]["input"];
}>;

export type CareerSubmitFormMutation = { __typename?: "Mutation"; careerSubmitForm: boolean };

export type GenerateSuggestedCareersMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateSuggestedCareersMutation = {
  __typename?: "Mutation";
  generateSuggestedCareers?: { __typename?: "Career"; name: string; id: string } | null;
};

export type SetCareerPathActiveMutationVariables = Exact<{
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type SetCareerPathActiveMutation = { __typename?: "Mutation"; setCareerPathActive: boolean };

export type DeleteAspectMutationVariables = Exact<{
  nodeId: Scalars["ID"]["input"];
}>;

export type DeleteAspectMutation = {
  __typename?: "Mutation";
  deleteAspect: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteMessageMutation = {
  __typename?: "Mutation";
  deleteMessage?: { __typename?: "MessageType"; id: string } | null;
};

export type GenerateKeywordsMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type GenerateKeywordsMutation = { __typename?: "Mutation"; generateYoutubeKeywords?: string | null };

export type CreateTodayDailyGoalsMutationVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type CreateTodayDailyGoalsMutation = {
  __typename?: "Mutation";
  createTodayDailyGoals: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type MarkQuestCompleteMutationVariables = Exact<{
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
}>;

export type MarkQuestCompleteMutation = {
  __typename?: "Mutation";
  markQuestComplete?: {
    __typename?: "Chat";
    id: string;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type RefreshQuestMutationVariables = Exact<{
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type RefreshQuestMutation = {
  __typename?: "Mutation";
  refreshQuest?: {
    __typename?: "RefreshQuestResponse";
    success: boolean;
    message: string;
    quests: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    }>;
  } | null;
};

export type AudioToggleUserMutationVariables = Exact<{
  audioOn: Scalars["Boolean"]["input"];
}>;

export type AudioToggleUserMutation = {
  __typename?: "Mutation";
  audioToggleUser?: { __typename?: "User"; audioOn?: boolean | null } | null;
};

export type UpdateNotificationsMutationVariables = Exact<{
  dailyEmailOptIn: Scalars["Boolean"]["input"];
}>;

export type UpdateNotificationsMutation = {
  __typename?: "Mutation";
  updateNotifications: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type AddToWhitelistMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type AddToWhitelistMutation = {
  __typename?: "Mutation";
  addToWhitelist: { __typename?: "WhitelistedEmail"; email: string };
};

export type CompleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type CompleteJourneyMutation = {
  __typename?: "Mutation";
  completeJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type DeleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type DeleteJourneyMutation = {
  __typename?: "Mutation";
  deleteJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type JourneySubmitFormMutationVariables = Exact<{
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type JourneySubmitFormMutation = {
  __typename?: "Mutation";
  journeySubmitForm?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type CreateEducationChildrenMutationVariables = Exact<{
  label: NodeLabel;
  name: Scalars["String"]["input"];
}>;

export type CreateEducationChildrenMutation = {
  __typename?: "Mutation";
  createEducationChildren: {
    __typename?: "Generated";
    success: boolean;
    message?: string | null;
    data?: Array<{ __typename?: "Subfield"; name: string } | null> | null;
  };
};

export type EnrollUserInTrackMutationVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type EnrollUserInTrackMutation = {
  __typename?: "Mutation";
  enrollUserInTrack: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetKnowledgeRelevanceMutationVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: Scalars["String"]["input"];
  isRelevant: Scalars["Boolean"]["input"];
}>;

export type SetKnowledgeRelevanceMutation = {
  __typename?: "Mutation";
  setKnowledgeRelevance: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateTopicMutationVariables = Exact<{
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
}>;

export type UpdateTopicMutation = {
  __typename?: "Mutation";
  updateTopic: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateLearningObjectiveCompletionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  completed: Scalars["Boolean"]["input"];
}>;

export type UpdateLearningObjectiveCompletionMutation = {
  __typename?: "Mutation";
  updateLearningObjectiveCompletion: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetOnboardingStatusMutationVariables = Exact<{
  isOnboarded: Scalars["Boolean"]["input"];
}>;

export type SetOnboardingStatusMutation = {
  __typename?: "Mutation";
  setOnboardingStatus: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type UpdateUserModalOnboardingMutationVariables = Exact<{
  modal_onboarding: Scalars["String"]["input"];
}>;

export type UpdateUserModalOnboardingMutation = {
  __typename?: "Mutation";
  updateUserModalOnboarding?: { __typename?: "User"; modal_onboarding?: string | null } | null;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type ResetAccountMutationVariables = Exact<{ [key: string]: never }>;

export type ResetAccountMutation = {
  __typename?: "Mutation";
  resetAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type AddTodoMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type AddTodoMutation = {
  __typename?: "Mutation";
  addTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
  } | null;
};

export type UpdateTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type UpdateTodoMutation = {
  __typename?: "Mutation";
  updateTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    completed: boolean;
    pointsReceived?: number | null;
    timezone?: string | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null;
};

export type SessionQueryVariables = Exact<{ [key: string]: never }>;

export type SessionQuery = { __typename?: "Query"; validateSession: { __typename?: "Session"; isValid: boolean } };

export type CareerByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CareerByIdQuery = {
  __typename?: "Query";
  getCareerById?: {
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null;
};

export type GetCareerHeaderQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareerHeaderQuery = {
  __typename?: "Query";
  getCareerHeader: {
    __typename?: "CareerHeader";
    jobTitle?: string | null;
    careerGoal?: string | null;
    careerName?: string | null;
    careerId?: string | null;
  };
};

export type GetCareerTopicsQueryVariables = Exact<{
  careerId: Scalars["ID"]["input"];
}>;

export type GetCareerTopicsQuery = {
  __typename?: "Query";
  getCareerTopics?: Array<{
    __typename?: "Topic";
    name: string;
    description?: string | null;
    mastery?: number | null;
  } | null> | null;
};

export type GetCareersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareersQuery = {
  __typename?: "Query";
  getCareers?: Array<{
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null> | null;
};

export type DailyLimitQueryVariables = Exact<{ [key: string]: never }>;

export type DailyLimitQuery = {
  __typename?: "Query";
  getDailyLimit?: { __typename?: "RateLimitResult"; dailyRequests?: number | null; result?: boolean | null } | null;
};

export type GetChatByContextAndParamsQueryVariables = Exact<{
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
}>;

export type GetChatByContextAndParamsQuery = {
  __typename?: "Query";
  getChatByContextAndParams?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageType";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetChatByIdQuery = {
  __typename?: "Query";
  getChatById?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageType";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByObjectIdQueryVariables = Exact<{
  objectId: Scalars["ID"]["input"];
  context: Scalars["String"]["input"];
}>;

export type GetChatByObjectIdQuery = {
  __typename?: "Query";
  getChatByObjectId?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "MessageType";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChatsQuery = {
  __typename?: "Query";
  getChats?: Array<{
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    url?: string | null;
  } | null> | null;
};

export type TodayChatQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayChatQuery = {
  __typename?: "Query";
  getTodayChat?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    timezone: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    userId: string;
    conversation: Array<{
      __typename?: "MessageType";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type SuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestionsQuery = {
  __typename?: "Query";
  getSuggestions?: Array<{
    __typename?: "Suggestion";
    type?: string | null;
    content?: string | null;
    integrationId?: string | null;
    classId?: string | null;
    assignmentId?: string | null;
    journeyId?: string | null;
    trackName?: string | null;
  } | null> | null;
};

export type LevelDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type LevelDetailsQuery = {
  __typename?: "Query";
  levelDetails?: {
    __typename?: "LevelDetails";
    name?: string | null;
    currentXP: number;
    level: number;
    xpToNextLevel: number;
    startingXPForCurrentLevel: number;
    progressPercentage: number;
  } | null;
};

export type TodayGameQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayGameQuery = {
  __typename?: "Query";
  getTodayGame?: {
    __typename?: "Game";
    chatId?: string | null;
    refreshCounter?: number | null;
    dailyTaskGoalForToday?: number | null;
    dailyTaskCompletedForToday?: number | null;
    dailyLearningModulesGoalForToday?: number | null;
    dailyLearningModulesCompletedForToday?: number | null;
    dailyCalorieGoalForToday?: number | null;
    dailyCalorieCompletedForToday?: number | null;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type DailyExerciseTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyExerciseTotalsByDateQuery = {
  __typename?: "Query";
  getDailyExerciseTotalsByDate?: {
    __typename?: "DailyExerciseTotals";
    totalCaloriesBurned?: number | null;
    totalDurationMinutes?: number | null;
    totalWorkouts?: number | null;
    workoutsList?: Array<{ __typename?: "Workout"; name?: string | null; type?: string | null } | null> | null;
  } | null;
};

export type DailyNutritionTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyNutritionTotalsByDateQuery = {
  __typename?: "Query";
  getDailyNutritionTotalsByDate?: {
    __typename?: "DailyNutritionTotals";
    totalCalories?: number | null;
    totalCarbs?: number | null;
    totalFats?: number | null;
    totalProteins?: number | null;
  } | null;
};

export type FoodByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type FoodByDateQuery = {
  __typename?: "Query";
  getFoodsByDate?: Array<{
    __typename?: "Food";
    name: string;
    calories?: number | null;
    carbohydrates_grams?: number | null;
    fat_grams?: number | null;
    fiber_grams?: number | null;
    protein_grams?: number | null;
    serving_size?: string | null;
    sodium_mg?: number | null;
    sugars_grams?: number | null;
  } | null> | null;
};

export type WaterIntakeByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type WaterIntakeByDateQuery = {
  __typename?: "Query";
  getWaterIntakeByDate?: {
    __typename?: "WaterIntakeSummary";
    totalWaterIntake: number;
    goalOz: number;
    percentageTowardsGoal: number;
  } | null;
};

export type InviteCountQueryVariables = Exact<{ [key: string]: never }>;

export type InviteCountQuery = {
  __typename?: "Query";
  userInviteCount: { __typename?: "InviteCount"; count: number };
  me?: {
    __typename?: "User";
    name: string;
    username?: string | null;
    gender?: string | null;
    is_onboarded?: boolean | null;
    isPro?: boolean | null;
    phoneNumber?: string | null;
    isEmailVerified?: boolean | null;
  } | null;
};

export type DateForChatQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DateForChatQuery = {
  __typename?: "Query";
  getChatForDate?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    timezone: string;
    userId: string;
    conversation: Array<{
      __typename?: "MessageType";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetJourneyByIdQueryVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type GetJourneyByIdQuery = {
  __typename?: "Query";
  getJourneyById?: {
    __typename?: "Journey";
    id: string;
    name: string;
    description?: string | null;
    dueDate?: string | null;
    url?: string | null;
    created?: any | null;
    last_modified?: any | null;
    tasks?: Array<{
      __typename?: "Todo";
      id: string;
      name: string;
      description?: string | null;
      priority?: string | null;
      date?: any | null;
      completed: boolean;
      isRecurring?: boolean | null;
      recurringFrequency?: string | null;
      recurringDaily?: boolean | null;
      recurringDayOfWeek?: string | null;
      recurringDayOfMonth?: number | null;
      recurringTime?: string | null;
      timeOfDay?: string | null;
      timezone?: string | null;
      isPartOfJourney?: boolean | null;
      journeyId?: string | null;
    } | null> | null;
  } | null;
};

export type GetJourneysQueryVariables = Exact<{ [key: string]: never }>;

export type GetJourneysQuery = {
  __typename?: "Query";
  getJourneys?: Array<{
    __typename?: "Journey";
    id: string;
    name: string;
    description?: string | null;
    url?: string | null;
    created?: any | null;
    last_modified?: any | null;
    dueDate?: string | null;
    totalTasks?: number | null;
    completedTasks?: number | null;
  } | null> | null;
};

export type FieldsQueryVariables = Exact<{ [key: string]: never }>;

export type FieldsQuery = {
  __typename?: "Query";
  getFields: Array<{
    __typename?: "Field";
    name: string;
    id: string;
    mastery?: number | null;
    isRelevant?: boolean | null;
    imageUrl?: string | null;
  } | null>;
};

export type GetLearningTreeQueryVariables = Exact<{
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type GetLearningTreeQuery = {
  __typename?: "Query";
  getLearningTree?: {
    __typename?: "LearningTree";
    FieldName?: string | null;
    SubfieldName?: string | null;
    TopicName?: string | null;
    ChapterName?: string | null;
    SubchapterName?: string | null;
  } | null;
};

export type NodeGraphQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type NodeGraphQuery = {
  __typename?: "Query";
  getNodeChildren: Array<{
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    unitsCount?: number | null;
    imageUrl?: string | null;
  } | null>;
  getNode?: {
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    imageUrl?: string | null;
  } | null;
  getNodeParents: Array<{
    __typename?: "LearningNode";
    name: string;
    id: string;
    mastery?: number | null;
    label?: string | null;
  } | null>;
};

export type RecentTracksQueryVariables = Exact<{
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RecentTracksQuery = {
  __typename?: "Query";
  getRecentTracks?: Array<{
    __typename?: "RecentTrack";
    name: string;
    description?: string | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentFields?: Array<string | null> | null;
    parentSubfield?: string | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type SiblingSubchaptersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  chapterName: Scalars["String"]["input"];
  subchapterName: Scalars["String"]["input"];
}>;

export type SiblingSubchaptersQuery = {
  __typename?: "Query";
  getSiblingSubchapters?: Array<{
    __typename?: "HierarchyNode";
    name: string;
    label: string;
    mastery?: number | null;
  } | null> | null;
};

export type SubchapterPrerequisitesQueryVariables = Exact<{
  subchapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SubchapterPrerequisitesQuery = {
  __typename?: "Query";
  getSubchapterPrerequisites?: Array<{
    __typename?: "HierarchyNode";
    label: string;
    name: string;
    mastery?: number | null;
  } | null> | null;
};

export type TableOfContentsQueryVariables = Exact<{
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
  hierarchyLabel: Scalars["String"]["input"];
}>;

export type TableOfContentsQuery = {
  __typename?: "Query";
  getTableOfContents?: Array<{
    __typename?: "Hierarchy";
    name: string;
    mastery: number;
    id?: string | null;
    isRelevant: boolean;
    parentName: string;
    description?: string | null;
    completed?: number | null;
    index?: number | null;
  } | null> | null;
};

export type TrackOverviewQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type TrackOverviewQuery = {
  __typename?: "Query";
  getTrackOverview?: {
    __typename?: "TrackOverview";
    name?: string | null;
    description?: string | null;
    objectives?: Array<string | null> | null;
    skillRequirements?: Array<string | null> | null;
    otherRequirements?: Array<string | null> | null;
    otherRequirementsTitle?: string | null;
    lessons?: Array<{ __typename?: "Lesson"; name?: string | null; description?: string | null } | null> | null;
    prerequisites?: Array<{
      __typename?: "Prerequisite";
      name?: string | null;
      subfield?: string | null;
      field?: string | null;
      mastery?: number | null;
    } | null> | null;
  } | null;
};

export type UserOverallProgressQueryVariables = Exact<{ [key: string]: never }>;

export type UserOverallProgressQuery = {
  __typename?: "Query";
  getUserOverallProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: { __typename?: "ChildInfo"; name: string; label: string };
    parent?: { __typename?: "ChildInfo"; label: string; name: string } | null;
  }>;
};

export type UserProgressQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type UserProgressQuery = {
  __typename?: "Query";
  getUserProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: {
      __typename?: "ChildInfo";
      name: string;
      label: string;
      totalTracks?: number | null;
      completedTracks?: number | null;
    };
  }>;
};

export type GetFirstSubchapterByTopicNameQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type GetFirstSubchapterByTopicNameQuery = {
  __typename?: "Query";
  getFirstSubchapterByTopicName: {
    __typename?: "SubchapterDetails";
    success: boolean;
    message?: string | null;
    id?: string | null;
    name?: string | null;
    description?: string | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  };
};

export type GetLearningObjectiveByIdQueryVariables = Exact<{
  loId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectiveByIdQuery = {
  __typename?: "Query";
  getLearningObjectiveById: {
    __typename?: "LearningObjectiveResponse";
    success: boolean;
    message?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    completed?: number | null;
  };
};

export type GetLearningObjectivesBySubchapterQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectivesBySubchapterQuery = {
  __typename?: "Query";
  getLearningObjectivesBySubchapter?: Array<{
    __typename?: "LearningObjective";
    id: string;
    title: string;
    description: string;
    index?: number | null;
    completed?: number | null;
  } | null> | null;
};

export type SubchapterByIdQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type SubchapterByIdQuery = {
  __typename?: "Query";
  getSubchapterById?: {
    __typename?: "SubchapterDetails";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    totalMastery?: number | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  } | null;
};

export type GetMasteryQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetMasteryQuery = {
  __typename?: "Query";
  getUserLearningObjectiveMastery?: {
    __typename?: "MasteryResult";
    learningObjectiveId?: string | null;
    userId?: string | null;
    totalMastery?: number | null;
  } | null;
};

export type ExportCsvQueryVariables = Exact<{ [key: string]: never }>;

export type ExportCsvQuery = {
  __typename?: "Query";
  exportCsv: { __typename?: "ExportCsvResponse"; success: boolean; csvData?: string | null; message?: string | null };
};

export type SchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolsQuery = {
  __typename?: "Query";
  getSchools?: Array<{
    __typename?: "School";
    id: string;
    providerName?: string | null;
    teamName?: string | null;
    icon?: string | null;
  } | null> | null;
};

export type DeleteTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteTodoMutation = {
  __typename?: "Mutation";
  deleteTodo?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type RecentTodosQueryVariables = Exact<{ [key: string]: never }>;

export type RecentTodosQuery = {
  __typename?: "Query";
  recentTodos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type TodosQueryVariables = Exact<{ [key: string]: never }>;

export type TodosQuery = {
  __typename?: "Query";
  todos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type GetActiveTracksQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveTracksQuery = {
  __typename?: "Query";
  getActiveTracks?: Array<{
    __typename?: "ActiveTrack";
    name?: string | null;
    description?: string | null;
    last_modified?: string | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentSubfield?: string | null;
    parentFields?: Array<string | null> | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "User";
    id: string;
    name: string;
    username?: string | null;
    gender?: string | null;
    is_onboarded?: boolean | null;
    modal_onboarding?: string | null;
    isPro?: boolean | null;
    trialEndDate?: any | null;
    audioOn?: boolean | null;
    phoneNumber?: string | null;
    isEmailVerified?: boolean | null;
    current_weight_lbs?: number | null;
    goal_weight_lbs?: number | null;
    googleCalendarAuthToken?: string | null;
    isCareerLoading?: boolean | null;
    enrolledTracks?: Array<string | null> | null;
    otpPreference?: string | null;
    countryCode?: string | null;
    fullPhoneNumber?: string | null;
    email: string;
    dailyEmailOptIn?: boolean | null;
  } | null;
};

export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        is_onboarded
        isPro
        name
        googleAuthTokens {
          access_token
          refresh_token
          scope
          token_type
          expiry_date
        }
        isEmailVerified
        otpPreference
        countryCode
        phoneNumber
        fullPhoneNumber
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      message
      success
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      message
      success
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $name: String!
    $password: String!
    $gender: String
    $birthday: String!
    $dailyEmailOptIn: Boolean!
    $language: String!
    $timezone: String!
  ) {
    signup(
      email: $email
      name: $name
      password: $password
      gender: $gender
      birthday: $birthday
      dailyEmailOptIn: $dailyEmailOptIn
      language: $language
      timezone: $timezone
    ) {
      token
      user {
        id
        email
        name
        isPro
        is_onboarded
        otpPreference
        isEmailVerified
        fullPhoneNumber
        preferences {
          language
        }
      }
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      gender: // value for 'gender'
 *      birthday: // value for 'birthday'
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *      language: // value for 'language'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const CareerIsLoadingDocument = gql`
  mutation careerIsLoading($isLoading: Boolean!) {
    careerIsLoading(isLoading: $isLoading) {
      isCareerLoading
    }
  }
`;
export type CareerIsLoadingMutationFn = Apollo.MutationFunction<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;

/**
 * __useCareerIsLoadingMutation__
 *
 * To run a mutation, you first call `useCareerIsLoadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerIsLoadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerIsLoadingMutation, { data, loading, error }] = useCareerIsLoadingMutation({
 *   variables: {
 *      isLoading: // value for 'isLoading'
 *   },
 * });
 */
export function useCareerIsLoadingMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>(
    CareerIsLoadingDocument,
    options
  );
}
export type CareerIsLoadingMutationHookResult = ReturnType<typeof useCareerIsLoadingMutation>;
export type CareerIsLoadingMutationResult = Apollo.MutationResult<CareerIsLoadingMutation>;
export type CareerIsLoadingMutationOptions = Apollo.BaseMutationOptions<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;
export const CareerSubmitFormDocument = gql`
  mutation CareerSubmitForm($currentOccupation: String!, $careerPathName: String!, $careerGoal: String!) {
    careerSubmitForm(currentOccupation: $currentOccupation, careerPathName: $careerPathName, careerGoal: $careerGoal)
  }
`;
export type CareerSubmitFormMutationFn = Apollo.MutationFunction<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;

/**
 * __useCareerSubmitFormMutation__
 *
 * To run a mutation, you first call `useCareerSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerSubmitFormMutation, { data, loading, error }] = useCareerSubmitFormMutation({
 *   variables: {
 *      currentOccupation: // value for 'currentOccupation'
 *      careerPathName: // value for 'careerPathName'
 *      careerGoal: // value for 'careerGoal'
 *   },
 * });
 */
export function useCareerSubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>(
    CareerSubmitFormDocument,
    options
  );
}
export type CareerSubmitFormMutationHookResult = ReturnType<typeof useCareerSubmitFormMutation>;
export type CareerSubmitFormMutationResult = Apollo.MutationResult<CareerSubmitFormMutation>;
export type CareerSubmitFormMutationOptions = Apollo.BaseMutationOptions<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;
export const GenerateSuggestedCareersDocument = gql`
  mutation generateSuggestedCareers {
    generateSuggestedCareers {
      name
      id
    }
  }
`;
export type GenerateSuggestedCareersMutationFn = Apollo.MutationFunction<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;

/**
 * __useGenerateSuggestedCareersMutation__
 *
 * To run a mutation, you first call `useGenerateSuggestedCareersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSuggestedCareersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSuggestedCareersMutation, { data, loading, error }] = useGenerateSuggestedCareersMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSuggestedCareersMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>(
    GenerateSuggestedCareersDocument,
    options
  );
}
export type GenerateSuggestedCareersMutationHookResult = ReturnType<typeof useGenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationResult = Apollo.MutationResult<GenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationOptions = Apollo.BaseMutationOptions<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;
export const SetCareerPathActiveDocument = gql`
  mutation SetCareerPathActive($careerId: ID) {
    setCareerPathActive(careerId: $careerId)
  }
`;
export type SetCareerPathActiveMutationFn = Apollo.MutationFunction<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;

/**
 * __useSetCareerPathActiveMutation__
 *
 * To run a mutation, you first call `useSetCareerPathActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareerPathActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareerPathActiveMutation, { data, loading, error }] = useSetCareerPathActiveMutation({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useSetCareerPathActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>(
    SetCareerPathActiveDocument,
    options
  );
}
export type SetCareerPathActiveMutationHookResult = ReturnType<typeof useSetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationResult = Apollo.MutationResult<SetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationOptions = Apollo.BaseMutationOptions<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;
export const DeleteAspectDocument = gql`
  mutation DeleteAspect($nodeId: ID!) {
    deleteAspect(nodeId: $nodeId) {
      message
      success
    }
  }
`;
export type DeleteAspectMutationFn = Apollo.MutationFunction<DeleteAspectMutation, DeleteAspectMutationVariables>;

/**
 * __useDeleteAspectMutation__
 *
 * To run a mutation, you first call `useDeleteAspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAspectMutation, { data, loading, error }] = useDeleteAspectMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteAspectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAspectMutation, DeleteAspectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAspectMutation, DeleteAspectMutationVariables>(DeleteAspectDocument, options);
}
export type DeleteAspectMutationHookResult = ReturnType<typeof useDeleteAspectMutation>;
export type DeleteAspectMutationResult = Apollo.MutationResult<DeleteAspectMutation>;
export type DeleteAspectMutationOptions = Apollo.BaseMutationOptions<
  DeleteAspectMutation,
  DeleteAspectMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation DeleteMessage($id: String!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const GenerateKeywordsDocument = gql`
  mutation GenerateKeywords($title: String!, $description: String!) {
    generateYoutubeKeywords(title: $title, description: $description)
  }
`;
export type GenerateKeywordsMutationFn = Apollo.MutationFunction<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;

/**
 * __useGenerateKeywordsMutation__
 *
 * To run a mutation, you first call `useGenerateKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateKeywordsMutation, { data, loading, error }] = useGenerateKeywordsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>(
    GenerateKeywordsDocument,
    options
  );
}
export type GenerateKeywordsMutationHookResult = ReturnType<typeof useGenerateKeywordsMutation>;
export type GenerateKeywordsMutationResult = Apollo.MutationResult<GenerateKeywordsMutation>;
export type GenerateKeywordsMutationOptions = Apollo.BaseMutationOptions<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;
export const CreateTodayDailyGoalsDocument = gql`
  mutation CreateTodayDailyGoals($timezone: String!) {
    createTodayDailyGoals(timezone: $timezone) {
      message
      success
    }
  }
`;
export type CreateTodayDailyGoalsMutationFn = Apollo.MutationFunction<
  CreateTodayDailyGoalsMutation,
  CreateTodayDailyGoalsMutationVariables
>;

/**
 * __useCreateTodayDailyGoalsMutation__
 *
 * To run a mutation, you first call `useCreateTodayDailyGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTodayDailyGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTodayDailyGoalsMutation, { data, loading, error }] = useCreateTodayDailyGoalsMutation({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateTodayDailyGoalsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTodayDailyGoalsMutation, CreateTodayDailyGoalsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTodayDailyGoalsMutation, CreateTodayDailyGoalsMutationVariables>(
    CreateTodayDailyGoalsDocument,
    options
  );
}
export type CreateTodayDailyGoalsMutationHookResult = ReturnType<typeof useCreateTodayDailyGoalsMutation>;
export type CreateTodayDailyGoalsMutationResult = Apollo.MutationResult<CreateTodayDailyGoalsMutation>;
export type CreateTodayDailyGoalsMutationOptions = Apollo.BaseMutationOptions<
  CreateTodayDailyGoalsMutation,
  CreateTodayDailyGoalsMutationVariables
>;
export const MarkQuestCompleteDocument = gql`
  mutation MarkQuestComplete($chatId: ID!, $questId: ID!) {
    markQuestComplete(chatId: $chatId, questId: $questId) {
      id
      assignedQuests {
        id
        title
        isComplete
      }
    }
  }
`;
export type MarkQuestCompleteMutationFn = Apollo.MutationFunction<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;

/**
 * __useMarkQuestCompleteMutation__
 *
 * To run a mutation, you first call `useMarkQuestCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuestCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuestCompleteMutation, { data, loading, error }] = useMarkQuestCompleteMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      questId: // value for 'questId'
 *   },
 * });
 */
export function useMarkQuestCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>(
    MarkQuestCompleteDocument,
    options
  );
}
export type MarkQuestCompleteMutationHookResult = ReturnType<typeof useMarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationResult = Apollo.MutationResult<MarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationOptions = Apollo.BaseMutationOptions<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;
export const RefreshQuestDocument = gql`
  mutation RefreshQuest($questIdToReplace: ID!, $timezone: String!) {
    refreshQuest(questIdToReplace: $questIdToReplace, timezone: $timezone) {
      success
      message
      quests {
        id
        title
        description
        isComplete
      }
    }
  }
`;
export type RefreshQuestMutationFn = Apollo.MutationFunction<RefreshQuestMutation, RefreshQuestMutationVariables>;

/**
 * __useRefreshQuestMutation__
 *
 * To run a mutation, you first call `useRefreshQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshQuestMutation, { data, loading, error }] = useRefreshQuestMutation({
 *   variables: {
 *      questIdToReplace: // value for 'questIdToReplace'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useRefreshQuestMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshQuestMutation, RefreshQuestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshQuestMutation, RefreshQuestMutationVariables>(RefreshQuestDocument, options);
}
export type RefreshQuestMutationHookResult = ReturnType<typeof useRefreshQuestMutation>;
export type RefreshQuestMutationResult = Apollo.MutationResult<RefreshQuestMutation>;
export type RefreshQuestMutationOptions = Apollo.BaseMutationOptions<
  RefreshQuestMutation,
  RefreshQuestMutationVariables
>;
export const AudioToggleUserDocument = gql`
  mutation AudioToggleUser($audioOn: Boolean!) {
    audioToggleUser(audioOn: $audioOn) {
      audioOn
    }
  }
`;
export type AudioToggleUserMutationFn = Apollo.MutationFunction<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;

/**
 * __useAudioToggleUserMutation__
 *
 * To run a mutation, you first call `useAudioToggleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioToggleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioToggleUserMutation, { data, loading, error }] = useAudioToggleUserMutation({
 *   variables: {
 *      audioOn: // value for 'audioOn'
 *   },
 * });
 */
export function useAudioToggleUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AudioToggleUserMutation, AudioToggleUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AudioToggleUserMutation, AudioToggleUserMutationVariables>(
    AudioToggleUserDocument,
    options
  );
}
export type AudioToggleUserMutationHookResult = ReturnType<typeof useAudioToggleUserMutation>;
export type AudioToggleUserMutationResult = Apollo.MutationResult<AudioToggleUserMutation>;
export type AudioToggleUserMutationOptions = Apollo.BaseMutationOptions<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;
export const UpdateNotificationsDocument = gql`
  mutation UpdateNotifications($dailyEmailOptIn: Boolean!) {
    updateNotifications(dailyEmailOptIn: $dailyEmailOptIn) {
      success
      message
    }
  }
`;
export type UpdateNotificationsMutationFn = Apollo.MutationFunction<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;

/**
 * __useUpdateNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsMutation, { data, loading, error }] = useUpdateNotificationsMutation({
 *   variables: {
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *   },
 * });
 */
export function useUpdateNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>(
    UpdateNotificationsDocument,
    options
  );
}
export type UpdateNotificationsMutationHookResult = ReturnType<typeof useUpdateNotificationsMutation>;
export type UpdateNotificationsMutationResult = Apollo.MutationResult<UpdateNotificationsMutation>;
export type UpdateNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;
export const AddToWhitelistDocument = gql`
  mutation AddToWhitelist($email: String!) {
    addToWhitelist(email: $email) {
      email
    }
  }
`;
export type AddToWhitelistMutationFn = Apollo.MutationFunction<AddToWhitelistMutation, AddToWhitelistMutationVariables>;

/**
 * __useAddToWhitelistMutation__
 *
 * To run a mutation, you first call `useAddToWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWhitelistMutation, { data, loading, error }] = useAddToWhitelistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddToWhitelistMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToWhitelistMutation, AddToWhitelistMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToWhitelistMutation, AddToWhitelistMutationVariables>(AddToWhitelistDocument, options);
}
export type AddToWhitelistMutationHookResult = ReturnType<typeof useAddToWhitelistMutation>;
export type AddToWhitelistMutationResult = Apollo.MutationResult<AddToWhitelistMutation>;
export type AddToWhitelistMutationOptions = Apollo.BaseMutationOptions<
  AddToWhitelistMutation,
  AddToWhitelistMutationVariables
>;
export const CompleteJourneyDocument = gql`
  mutation CompleteJourney($journeyId: ID!) {
    completeJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type CompleteJourneyMutationFn = Apollo.MutationFunction<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;

/**
 * __useCompleteJourneyMutation__
 *
 * To run a mutation, you first call `useCompleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeJourneyMutation, { data, loading, error }] = useCompleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useCompleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteJourneyMutation, CompleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteJourneyMutation, CompleteJourneyMutationVariables>(
    CompleteJourneyDocument,
    options
  );
}
export type CompleteJourneyMutationHookResult = ReturnType<typeof useCompleteJourneyMutation>;
export type CompleteJourneyMutationResult = Apollo.MutationResult<CompleteJourneyMutation>;
export type CompleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;
export const DeleteJourneyDocument = gql`
  mutation DeleteJourney($journeyId: ID!) {
    deleteJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type DeleteJourneyMutationFn = Apollo.MutationFunction<DeleteJourneyMutation, DeleteJourneyMutationVariables>;

/**
 * __useDeleteJourneyMutation__
 *
 * To run a mutation, you first call `useDeleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJourneyMutation, { data, loading, error }] = useDeleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useDeleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJourneyMutation, DeleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJourneyMutation, DeleteJourneyMutationVariables>(DeleteJourneyDocument, options);
}
export type DeleteJourneyMutationHookResult = ReturnType<typeof useDeleteJourneyMutation>;
export type DeleteJourneyMutationResult = Apollo.MutationResult<DeleteJourneyMutation>;
export type DeleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  DeleteJourneyMutation,
  DeleteJourneyMutationVariables
>;
export const JourneySubmitFormDocument = gql`
  mutation JourneySubmitForm($description: String!, $dueDate: String!, $timezone: String!) {
    journeySubmitForm(description: $description, dueDate: $dueDate, timezone: $timezone) {
      success
      message
    }
  }
`;
export type JourneySubmitFormMutationFn = Apollo.MutationFunction<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;

/**
 * __useJourneySubmitFormMutation__
 *
 * To run a mutation, you first call `useJourneySubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJourneySubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journeySubmitFormMutation, { data, loading, error }] = useJourneySubmitFormMutation({
 *   variables: {
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useJourneySubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>(
    JourneySubmitFormDocument,
    options
  );
}
export type JourneySubmitFormMutationHookResult = ReturnType<typeof useJourneySubmitFormMutation>;
export type JourneySubmitFormMutationResult = Apollo.MutationResult<JourneySubmitFormMutation>;
export type JourneySubmitFormMutationOptions = Apollo.BaseMutationOptions<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;
export const CreateEducationChildrenDocument = gql`
  mutation CreateEducationChildren($label: NodeLabel!, $name: String!) {
    createEducationChildren(label: $label, name: $name) {
      success
      message
      data {
        name
      }
    }
  }
`;
export type CreateEducationChildrenMutationFn = Apollo.MutationFunction<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;

/**
 * __useCreateEducationChildrenMutation__
 *
 * To run a mutation, you first call `useCreateEducationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationChildrenMutation, { data, loading, error }] = useCreateEducationChildrenMutation({
 *   variables: {
 *      label: // value for 'label'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEducationChildrenMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>(
    CreateEducationChildrenDocument,
    options
  );
}
export type CreateEducationChildrenMutationHookResult = ReturnType<typeof useCreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationResult = Apollo.MutationResult<CreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationOptions = Apollo.BaseMutationOptions<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;
export const EnrollUserInTrackDocument = gql`
  mutation EnrollUserInTrack($topicName: String!) {
    enrollUserInTrack(topicName: $topicName) {
      success
      message
    }
  }
`;
export type EnrollUserInTrackMutationFn = Apollo.MutationFunction<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;

/**
 * __useEnrollUserInTrackMutation__
 *
 * To run a mutation, you first call `useEnrollUserInTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollUserInTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollUserInTrackMutation, { data, loading, error }] = useEnrollUserInTrackMutation({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useEnrollUserInTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>(
    EnrollUserInTrackDocument,
    options
  );
}
export type EnrollUserInTrackMutationHookResult = ReturnType<typeof useEnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationResult = Apollo.MutationResult<EnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationOptions = Apollo.BaseMutationOptions<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;
export const SetKnowledgeRelevanceDocument = gql`
  mutation SetKnowledgeRelevance($nodeName: String!, $nodeLabel: String!, $isRelevant: Boolean!) {
    setKnowledgeRelevance(nodeName: $nodeName, nodeLabel: $nodeLabel, isRelevant: $isRelevant) {
      success
      message
    }
  }
`;
export type SetKnowledgeRelevanceMutationFn = Apollo.MutationFunction<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;

/**
 * __useSetKnowledgeRelevanceMutation__
 *
 * To run a mutation, you first call `useSetKnowledgeRelevanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKnowledgeRelevanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKnowledgeRelevanceMutation, { data, loading, error }] = useSetKnowledgeRelevanceMutation({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *      isRelevant: // value for 'isRelevant'
 *   },
 * });
 */
export function useSetKnowledgeRelevanceMutation(
  baseOptions?: Apollo.MutationHookOptions<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>(
    SetKnowledgeRelevanceDocument,
    options
  );
}
export type SetKnowledgeRelevanceMutationHookResult = ReturnType<typeof useSetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationResult = Apollo.MutationResult<SetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationOptions = Apollo.BaseMutationOptions<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;
export const UpdateTopicDocument = gql`
  mutation UpdateTopic($operationType: String!, $topicName: String!) {
    updateTopic(operationType: $operationType, topicName: $topicName) {
      success
      message
    }
  }
`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      operationType: // value for 'operationType'
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useUpdateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
}
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const UpdateLearningObjectiveCompletionDocument = gql`
  mutation UpdateLearningObjectiveCompletion($id: ID!, $completed: Boolean!) {
    updateLearningObjectiveCompletion(id: $id, completed: $completed) {
      success
      message
    }
  }
`;
export type UpdateLearningObjectiveCompletionMutationFn = Apollo.MutationFunction<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;

/**
 * __useUpdateLearningObjectiveCompletionMutation__
 *
 * To run a mutation, you first call `useUpdateLearningObjectiveCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningObjectiveCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningObjectiveCompletionMutation, { data, loading, error }] = useUpdateLearningObjectiveCompletionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useUpdateLearningObjectiveCompletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >(UpdateLearningObjectiveCompletionDocument, options);
}
export type UpdateLearningObjectiveCompletionMutationHookResult = ReturnType<
  typeof useUpdateLearningObjectiveCompletionMutation
>;
export type UpdateLearningObjectiveCompletionMutationResult =
  Apollo.MutationResult<UpdateLearningObjectiveCompletionMutation>;
export type UpdateLearningObjectiveCompletionMutationOptions = Apollo.BaseMutationOptions<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;
export const SetOnboardingStatusDocument = gql`
  mutation SetOnboardingStatus($isOnboarded: Boolean!) {
    setOnboardingStatus(isOnboarded: $isOnboarded) {
      message
      success
    }
  }
`;
export type SetOnboardingStatusMutationFn = Apollo.MutationFunction<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;

/**
 * __useSetOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useSetOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOnboardingStatusMutation, { data, loading, error }] = useSetOnboardingStatusMutation({
 *   variables: {
 *      isOnboarded: // value for 'isOnboarded'
 *   },
 * });
 */
export function useSetOnboardingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>(
    SetOnboardingStatusDocument,
    options
  );
}
export type SetOnboardingStatusMutationHookResult = ReturnType<typeof useSetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationResult = Apollo.MutationResult<SetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;
export const UpdateUserModalOnboardingDocument = gql`
  mutation UpdateUserModalOnboarding($modal_onboarding: String!) {
    updateUserModalOnboarding(modal_onboarding: $modal_onboarding) {
      modal_onboarding
    }
  }
`;
export type UpdateUserModalOnboardingMutationFn = Apollo.MutationFunction<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;

/**
 * __useUpdateUserModalOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserModalOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserModalOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserModalOnboardingMutation, { data, loading, error }] = useUpdateUserModalOnboardingMutation({
 *   variables: {
 *      modal_onboarding: // value for 'modal_onboarding'
 *   },
 * });
 */
export function useUpdateUserModalOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserModalOnboardingMutation,
    UpdateUserModalOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserModalOnboardingMutation, UpdateUserModalOnboardingMutationVariables>(
    UpdateUserModalOnboardingDocument,
    options
  );
}
export type UpdateUserModalOnboardingMutationHookResult = ReturnType<typeof useUpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationResult = Apollo.MutationResult<UpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount {
      success
      message
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const ResetAccountDocument = gql`
  mutation resetAccount {
    resetAccount {
      success
      message
    }
  }
`;
export type ResetAccountMutationFn = Apollo.MutationFunction<ResetAccountMutation, ResetAccountMutationVariables>;

/**
 * __useResetAccountMutation__
 *
 * To run a mutation, you first call `useResetAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountMutation, { data, loading, error }] = useResetAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetAccountMutation, ResetAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetAccountMutation, ResetAccountMutationVariables>(ResetAccountDocument, options);
}
export type ResetAccountMutationHookResult = ReturnType<typeof useResetAccountMutation>;
export type ResetAccountMutationResult = Apollo.MutationResult<ResetAccountMutation>;
export type ResetAccountMutationOptions = Apollo.BaseMutationOptions<
  ResetAccountMutation,
  ResetAccountMutationVariables
>;
export const AddTodoDocument = gql`
  mutation AddTodo(
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $timezone: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    addTodo(
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      timezone: $timezone
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
    }
  }
`;
export type AddTodoMutationFn = Apollo.MutationFunction<AddTodoMutation, AddTodoMutationVariables>;

/**
 * __useAddTodoMutation__
 *
 * To run a mutation, you first call `useAddTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTodoMutation, { data, loading, error }] = useAddTodoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      timezone: // value for 'timezone'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useAddTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTodoMutation, AddTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTodoMutation, AddTodoMutationVariables>(AddTodoDocument, options);
}
export type AddTodoMutationHookResult = ReturnType<typeof useAddTodoMutation>;
export type AddTodoMutationResult = Apollo.MutationResult<AddTodoMutation>;
export type AddTodoMutationOptions = Apollo.BaseMutationOptions<AddTodoMutation, AddTodoMutationVariables>;
export const UpdateTodoDocument = gql`
  mutation UpdateTodo(
    $id: String!
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $completed: Boolean
    $pointsReceived: Int
    $timezone: String!
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    updateTodo(
      id: $id
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      completed: $completed
      pointsReceived: $pointsReceived
      timezone: $timezone
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      completed
      pointsReceived
      timezone
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      isPartOfJourney
      journeyId
    }
  }
`;
export type UpdateTodoMutationFn = Apollo.MutationFunction<UpdateTodoMutation, UpdateTodoMutationVariables>;

/**
 * __useUpdateTodoMutation__
 *
 * To run a mutation, you first call `useUpdateTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodoMutation, { data, loading, error }] = useUpdateTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      completed: // value for 'completed'
 *      pointsReceived: // value for 'pointsReceived'
 *      timezone: // value for 'timezone'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useUpdateTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTodoMutation, UpdateTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTodoMutation, UpdateTodoMutationVariables>(UpdateTodoDocument, options);
}
export type UpdateTodoMutationHookResult = ReturnType<typeof useUpdateTodoMutation>;
export type UpdateTodoMutationResult = Apollo.MutationResult<UpdateTodoMutation>;
export type UpdateTodoMutationOptions = Apollo.BaseMutationOptions<UpdateTodoMutation, UpdateTodoMutationVariables>;
export const SessionDocument = gql`
  query Session {
    validateSession {
      isValid
    }
  }
`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SessionQuery, SessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionSuspenseQueryHookResult = ReturnType<typeof useSessionSuspenseQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const CareerByIdDocument = gql`
  query CareerById($id: ID!) {
    getCareerById(id: $id) {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useCareerByIdQuery__
 *
 * To run a query within a React component, call `useCareerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables> &
    ({ variables: CareerByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export type CareerByIdQueryHookResult = ReturnType<typeof useCareerByIdQuery>;
export type CareerByIdLazyQueryHookResult = ReturnType<typeof useCareerByIdLazyQuery>;
export type CareerByIdSuspenseQueryHookResult = ReturnType<typeof useCareerByIdSuspenseQuery>;
export type CareerByIdQueryResult = Apollo.QueryResult<CareerByIdQuery, CareerByIdQueryVariables>;
export const GetCareerHeaderDocument = gql`
  query GetCareerHeader {
    getCareerHeader {
      jobTitle
      careerGoal
      careerName
      careerId
    }
  }
`;

/**
 * __useGetCareerHeaderQuery__
 *
 * To run a query within a React component, call `useGetCareerHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareerHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export type GetCareerHeaderQueryHookResult = ReturnType<typeof useGetCareerHeaderQuery>;
export type GetCareerHeaderLazyQueryHookResult = ReturnType<typeof useGetCareerHeaderLazyQuery>;
export type GetCareerHeaderSuspenseQueryHookResult = ReturnType<typeof useGetCareerHeaderSuspenseQuery>;
export type GetCareerHeaderQueryResult = Apollo.QueryResult<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>;
export const GetCareerTopicsDocument = gql`
  query getCareerTopics($careerId: ID!) {
    getCareerTopics(careerId: $careerId) {
      name
      description
      mastery
    }
  }
`;

/**
 * __useGetCareerTopicsQuery__
 *
 * To run a query within a React component, call `useGetCareerTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerTopicsQuery({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useGetCareerTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables> &
    ({ variables: GetCareerTopicsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export type GetCareerTopicsQueryHookResult = ReturnType<typeof useGetCareerTopicsQuery>;
export type GetCareerTopicsLazyQueryHookResult = ReturnType<typeof useGetCareerTopicsLazyQuery>;
export type GetCareerTopicsSuspenseQueryHookResult = ReturnType<typeof useGetCareerTopicsSuspenseQuery>;
export type GetCareerTopicsQueryResult = Apollo.QueryResult<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>;
export const GetCareersDocument = gql`
  query GetCareers {
    getCareers {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useGetCareersQuery__
 *
 * To run a query within a React component, call `useGetCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareersQuery(baseOptions?: Apollo.QueryHookOptions<GetCareersQuery, GetCareersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export type GetCareersQueryHookResult = ReturnType<typeof useGetCareersQuery>;
export type GetCareersLazyQueryHookResult = ReturnType<typeof useGetCareersLazyQuery>;
export type GetCareersSuspenseQueryHookResult = ReturnType<typeof useGetCareersSuspenseQuery>;
export type GetCareersQueryResult = Apollo.QueryResult<GetCareersQuery, GetCareersQueryVariables>;
export const DailyLimitDocument = gql`
  query DailyLimit {
    getDailyLimit {
      dailyRequests
      result
    }
  }
`;

/**
 * __useDailyLimitQuery__
 *
 * To run a query within a React component, call `useDailyLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyLimitQuery(baseOptions?: Apollo.QueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export type DailyLimitQueryHookResult = ReturnType<typeof useDailyLimitQuery>;
export type DailyLimitLazyQueryHookResult = ReturnType<typeof useDailyLimitLazyQuery>;
export type DailyLimitSuspenseQueryHookResult = ReturnType<typeof useDailyLimitSuspenseQuery>;
export type DailyLimitQueryResult = Apollo.QueryResult<DailyLimitQuery, DailyLimitQueryVariables>;
export const GetChatByContextAndParamsDocument = gql`
  query GetChatByContextAndParams($context: String!, $params: JSON) {
    getChatByContextAndParams(context: $context, params: $params) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByContextAndParamsQuery__
 *
 * To run a query within a React component, call `useGetChatByContextAndParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByContextAndParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByContextAndParamsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetChatByContextAndParamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables> &
    ({ variables: GetChatByContextAndParamsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export type GetChatByContextAndParamsQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsQuery>;
export type GetChatByContextAndParamsLazyQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsLazyQuery>;
export type GetChatByContextAndParamsSuspenseQueryHookResult = ReturnType<
  typeof useGetChatByContextAndParamsSuspenseQuery
>;
export type GetChatByContextAndParamsQueryResult = Apollo.QueryResult<
  GetChatByContextAndParamsQuery,
  GetChatByContextAndParamsQueryVariables
>;
export const GetChatByIdDocument = gql`
  query GetChatById($id: ID!) {
    getChatById(id: $id) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByIdQuery__
 *
 * To run a query within a React component, call `useGetChatByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables> &
    ({ variables: GetChatByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export type GetChatByIdQueryHookResult = ReturnType<typeof useGetChatByIdQuery>;
export type GetChatByIdLazyQueryHookResult = ReturnType<typeof useGetChatByIdLazyQuery>;
export type GetChatByIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByIdSuspenseQuery>;
export type GetChatByIdQueryResult = Apollo.QueryResult<GetChatByIdQuery, GetChatByIdQueryVariables>;
export const GetChatByObjectIdDocument = gql`
  query GetChatByObjectId($objectId: ID!, $context: String!) {
    getChatByObjectId(objectId: $objectId, context: $context) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByObjectIdQuery__
 *
 * To run a query within a React component, call `useGetChatByObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByObjectIdQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetChatByObjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables> &
    ({ variables: GetChatByObjectIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(GetChatByObjectIdDocument, options);
}
export function useGetChatByObjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export function useGetChatByObjectIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export type GetChatByObjectIdQueryHookResult = ReturnType<typeof useGetChatByObjectIdQuery>;
export type GetChatByObjectIdLazyQueryHookResult = ReturnType<typeof useGetChatByObjectIdLazyQuery>;
export type GetChatByObjectIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByObjectIdSuspenseQuery>;
export type GetChatByObjectIdQueryResult = Apollo.QueryResult<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>;
export const GetChatsDocument = gql`
  query GetChats {
    getChats {
      id
      date
      name
      userId
      objectId
      context
      params
      url
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsSuspenseQueryHookResult = ReturnType<typeof useGetChatsSuspenseQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const TodayChatDocument = gql`
  query TodayChat($timezone: String!) {
    getTodayChat(timezone: $timezone) {
      id
      date
      name
      timezone
      objectId
      context
      params
      userId
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useTodayChatQuery__
 *
 * To run a query within a React component, call `useTodayChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayChatQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayChatQuery(
  baseOptions: Apollo.QueryHookOptions<TodayChatQuery, TodayChatQueryVariables> &
    ({ variables: TodayChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export type TodayChatQueryHookResult = ReturnType<typeof useTodayChatQuery>;
export type TodayChatLazyQueryHookResult = ReturnType<typeof useTodayChatLazyQuery>;
export type TodayChatSuspenseQueryHookResult = ReturnType<typeof useTodayChatSuspenseQuery>;
export type TodayChatQueryResult = Apollo.QueryResult<TodayChatQuery, TodayChatQueryVariables>;
export const SuggestionsDocument = gql`
  query Suggestions {
    getSuggestions {
      type
      content
      integrationId
      classId
      assignmentId
      journeyId
      trackName
    }
  }
`;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<typeof useSuggestionsLazyQuery>;
export type SuggestionsSuspenseQueryHookResult = ReturnType<typeof useSuggestionsSuspenseQuery>;
export type SuggestionsQueryResult = Apollo.QueryResult<SuggestionsQuery, SuggestionsQueryVariables>;
export const LevelDetailsDocument = gql`
  query LevelDetails {
    levelDetails: getLevelDetails {
      name
      currentXP
      level
      xpToNextLevel
      startingXPForCurrentLevel
      progressPercentage
    }
  }
`;

/**
 * __useLevelDetailsQuery__
 *
 * To run a query within a React component, call `useLevelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLevelDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export function useLevelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export function useLevelDetailsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export type LevelDetailsQueryHookResult = ReturnType<typeof useLevelDetailsQuery>;
export type LevelDetailsLazyQueryHookResult = ReturnType<typeof useLevelDetailsLazyQuery>;
export type LevelDetailsSuspenseQueryHookResult = ReturnType<typeof useLevelDetailsSuspenseQuery>;
export type LevelDetailsQueryResult = Apollo.QueryResult<LevelDetailsQuery, LevelDetailsQueryVariables>;
export const TodayGameDocument = gql`
  query TodayGame($timezone: String!) {
    getTodayGame(timezone: $timezone) {
      chatId
      refreshCounter
      dailyTaskGoalForToday
      dailyTaskCompletedForToday
      dailyLearningModulesGoalForToday
      dailyLearningModulesCompletedForToday
      dailyCalorieGoalForToday
      dailyCalorieCompletedForToday
      assignedQuests {
        id
        title
        description
        isComplete
      }
    }
  }
`;

/**
 * __useTodayGameQuery__
 *
 * To run a query within a React component, call `useTodayGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayGameQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayGameQuery(
  baseOptions: Apollo.QueryHookOptions<TodayGameQuery, TodayGameQueryVariables> &
    ({ variables: TodayGameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export type TodayGameQueryHookResult = ReturnType<typeof useTodayGameQuery>;
export type TodayGameLazyQueryHookResult = ReturnType<typeof useTodayGameLazyQuery>;
export type TodayGameSuspenseQueryHookResult = ReturnType<typeof useTodayGameSuspenseQuery>;
export type TodayGameQueryResult = Apollo.QueryResult<TodayGameQuery, TodayGameQueryVariables>;
export const DailyExerciseTotalsByDateDocument = gql`
  query DailyExerciseTotalsByDate($date: String!, $timezone: String!) {
    getDailyExerciseTotalsByDate(date: $date, timezone: $timezone) {
      totalCaloriesBurned
      totalDurationMinutes
      totalWorkouts
      workoutsList {
        name
        type
      }
    }
  }
`;

/**
 * __useDailyExerciseTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyExerciseTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyExerciseTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyExerciseTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyExerciseTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables> &
    ({ variables: DailyExerciseTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export type DailyExerciseTotalsByDateQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateQuery>;
export type DailyExerciseTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateLazyQuery>;
export type DailyExerciseTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyExerciseTotalsByDateSuspenseQuery
>;
export type DailyExerciseTotalsByDateQueryResult = Apollo.QueryResult<
  DailyExerciseTotalsByDateQuery,
  DailyExerciseTotalsByDateQueryVariables
>;
export const DailyNutritionTotalsByDateDocument = gql`
  query DailyNutritionTotalsByDate($date: String!, $timezone: String!) {
    getDailyNutritionTotalsByDate(date: $date, timezone: $timezone) {
      totalCalories
      totalCarbs
      totalFats
      totalProteins
    }
  }
`;

/**
 * __useDailyNutritionTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyNutritionTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyNutritionTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyNutritionTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyNutritionTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables> &
    ({ variables: DailyNutritionTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DailyNutritionTotalsByDateQuery,
    DailyNutritionTotalsByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export type DailyNutritionTotalsByDateQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateQuery>;
export type DailyNutritionTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateLazyQuery>;
export type DailyNutritionTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyNutritionTotalsByDateSuspenseQuery
>;
export type DailyNutritionTotalsByDateQueryResult = Apollo.QueryResult<
  DailyNutritionTotalsByDateQuery,
  DailyNutritionTotalsByDateQueryVariables
>;
export const FoodByDateDocument = gql`
  query FoodByDate($date: String!, $timezone: String!) {
    getFoodsByDate(date: $date, timezone: $timezone) {
      name
      calories
      carbohydrates_grams
      fat_grams
      fiber_grams
      protein_grams
      serving_size
      sodium_mg
      sugars_grams
    }
  }
`;

/**
 * __useFoodByDateQuery__
 *
 * To run a query within a React component, call `useFoodByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useFoodByDateQuery(
  baseOptions: Apollo.QueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables> &
    ({ variables: FoodByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export type FoodByDateQueryHookResult = ReturnType<typeof useFoodByDateQuery>;
export type FoodByDateLazyQueryHookResult = ReturnType<typeof useFoodByDateLazyQuery>;
export type FoodByDateSuspenseQueryHookResult = ReturnType<typeof useFoodByDateSuspenseQuery>;
export type FoodByDateQueryResult = Apollo.QueryResult<FoodByDateQuery, FoodByDateQueryVariables>;
export const WaterIntakeByDateDocument = gql`
  query WaterIntakeByDate($date: String!, $timezone: String!) {
    getWaterIntakeByDate(date: $date, timezone: $timezone) {
      totalWaterIntake
      goalOz
      percentageTowardsGoal
    }
  }
`;

/**
 * __useWaterIntakeByDateQuery__
 *
 * To run a query within a React component, call `useWaterIntakeByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterIntakeByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterIntakeByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useWaterIntakeByDateQuery(
  baseOptions: Apollo.QueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables> &
    ({ variables: WaterIntakeByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(WaterIntakeByDateDocument, options);
}
export function useWaterIntakeByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export function useWaterIntakeByDateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export type WaterIntakeByDateQueryHookResult = ReturnType<typeof useWaterIntakeByDateQuery>;
export type WaterIntakeByDateLazyQueryHookResult = ReturnType<typeof useWaterIntakeByDateLazyQuery>;
export type WaterIntakeByDateSuspenseQueryHookResult = ReturnType<typeof useWaterIntakeByDateSuspenseQuery>;
export type WaterIntakeByDateQueryResult = Apollo.QueryResult<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>;
export const InviteCountDocument = gql`
  query InviteCount {
    userInviteCount {
      count
    }
    me {
      name
      username
      gender
      is_onboarded
      isPro
      phoneNumber
      isEmailVerified
    }
  }
`;

/**
 * __useInviteCountQuery__
 *
 * To run a query within a React component, call `useInviteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteCountQuery(
  baseOptions?: Apollo.QueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export type InviteCountQueryHookResult = ReturnType<typeof useInviteCountQuery>;
export type InviteCountLazyQueryHookResult = ReturnType<typeof useInviteCountLazyQuery>;
export type InviteCountSuspenseQueryHookResult = ReturnType<typeof useInviteCountSuspenseQuery>;
export type InviteCountQueryResult = Apollo.QueryResult<InviteCountQuery, InviteCountQueryVariables>;
export const DateForChatDocument = gql`
  query DateForChat($date: String!, $timezone: String!) {
    getChatForDate(date: $date, timezone: $timezone) {
      id
      date
      timezone
      userId
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useDateForChatQuery__
 *
 * To run a query within a React component, call `useDateForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDateForChatQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDateForChatQuery(
  baseOptions: Apollo.QueryHookOptions<DateForChatQuery, DateForChatQueryVariables> &
    ({ variables: DateForChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export type DateForChatQueryHookResult = ReturnType<typeof useDateForChatQuery>;
export type DateForChatLazyQueryHookResult = ReturnType<typeof useDateForChatLazyQuery>;
export type DateForChatSuspenseQueryHookResult = ReturnType<typeof useDateForChatSuspenseQuery>;
export type DateForChatQueryResult = Apollo.QueryResult<DateForChatQuery, DateForChatQueryVariables>;
export const GetJourneyByIdDocument = gql`
  query GetJourneyById($journeyId: ID!) {
    getJourneyById(journeyId: $journeyId) {
      id
      name
      description
      dueDate
      url
      created
      last_modified
      tasks {
        id
        name
        description
        priority
        date
        completed
        isRecurring
        recurringFrequency
        recurringDaily
        recurringDayOfWeek
        recurringDayOfMonth
        recurringTime
        timeOfDay
        timezone
        isPartOfJourney
        journeyId
      }
    }
  }
`;

/**
 * __useGetJourneyByIdQuery__
 *
 * To run a query within a React component, call `useGetJourneyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneyByIdQuery({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useGetJourneyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables> &
    ({ variables: GetJourneyByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export type GetJourneyByIdQueryHookResult = ReturnType<typeof useGetJourneyByIdQuery>;
export type GetJourneyByIdLazyQueryHookResult = ReturnType<typeof useGetJourneyByIdLazyQuery>;
export type GetJourneyByIdSuspenseQueryHookResult = ReturnType<typeof useGetJourneyByIdSuspenseQuery>;
export type GetJourneyByIdQueryResult = Apollo.QueryResult<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>;
export const GetJourneysDocument = gql`
  query GetJourneys {
    getJourneys {
      id
      name
      description
      url
      created
      last_modified
      dueDate
      totalTasks
      completedTasks
    }
  }
`;

/**
 * __useGetJourneysQuery__
 *
 * To run a query within a React component, call `useGetJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJourneysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export type GetJourneysQueryHookResult = ReturnType<typeof useGetJourneysQuery>;
export type GetJourneysLazyQueryHookResult = ReturnType<typeof useGetJourneysLazyQuery>;
export type GetJourneysSuspenseQueryHookResult = ReturnType<typeof useGetJourneysSuspenseQuery>;
export type GetJourneysQueryResult = Apollo.QueryResult<GetJourneysQuery, GetJourneysQueryVariables>;
export const FieldsDocument = gql`
  query Fields {
    getFields {
      name
      id
      mastery
      isRelevant
      imageUrl
    }
  }
`;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldsQuery(baseOptions?: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FieldsQuery, FieldsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsSuspenseQueryHookResult = ReturnType<typeof useFieldsSuspenseQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;
export const GetLearningTreeDocument = gql`
  query GetLearningTree($level: String!, $name: String!) {
    getLearningTree(level: $level, name: $name) {
      FieldName
      SubfieldName
      TopicName
      ChapterName
      SubchapterName
    }
  }
`;

/**
 * __useGetLearningTreeQuery__
 *
 * To run a query within a React component, call `useGetLearningTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningTreeQuery({
 *   variables: {
 *      level: // value for 'level'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetLearningTreeQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables> &
    ({ variables: GetLearningTreeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export type GetLearningTreeQueryHookResult = ReturnType<typeof useGetLearningTreeQuery>;
export type GetLearningTreeLazyQueryHookResult = ReturnType<typeof useGetLearningTreeLazyQuery>;
export type GetLearningTreeSuspenseQueryHookResult = ReturnType<typeof useGetLearningTreeSuspenseQuery>;
export type GetLearningTreeQueryResult = Apollo.QueryResult<GetLearningTreeQuery, GetLearningTreeQueryVariables>;
export const NodeGraphDocument = gql`
  query NodeGraph($nodeName: String!, $nodeLabel: NodeLabel!) {
    getNodeChildren(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      unitsCount
      imageUrl
    }
    getNode(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      imageUrl
    }
    getNodeParents(label: $nodeLabel, name: $nodeName) {
      name
      id
      mastery
      label
    }
  }
`;

/**
 * __useNodeGraphQuery__
 *
 * To run a query within a React component, call `useNodeGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeGraphQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useNodeGraphQuery(
  baseOptions: Apollo.QueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables> &
    ({ variables: NodeGraphQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export type NodeGraphQueryHookResult = ReturnType<typeof useNodeGraphQuery>;
export type NodeGraphLazyQueryHookResult = ReturnType<typeof useNodeGraphLazyQuery>;
export type NodeGraphSuspenseQueryHookResult = ReturnType<typeof useNodeGraphSuspenseQuery>;
export type NodeGraphQueryResult = Apollo.QueryResult<NodeGraphQuery, NodeGraphQueryVariables>;
export const RecentTracksDocument = gql`
  query RecentTracks($filterByCategory: String) {
    getRecentTracks(filterByCategory: $filterByCategory) {
      name
      description
      totalUnits
      completedUnits
      parentFields
      parentSubfield
      mastery
      imageUrl
    }
  }
`;

/**
 * __useRecentTracksQuery__
 *
 * To run a query within a React component, call `useRecentTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTracksQuery({
 *   variables: {
 *      filterByCategory: // value for 'filterByCategory'
 *   },
 * });
 */
export function useRecentTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export type RecentTracksQueryHookResult = ReturnType<typeof useRecentTracksQuery>;
export type RecentTracksLazyQueryHookResult = ReturnType<typeof useRecentTracksLazyQuery>;
export type RecentTracksSuspenseQueryHookResult = ReturnType<typeof useRecentTracksSuspenseQuery>;
export type RecentTracksQueryResult = Apollo.QueryResult<RecentTracksQuery, RecentTracksQueryVariables>;
export const SiblingSubchaptersDocument = gql`
  query SiblingSubchapters($limit: Int!, $chapterName: String!, $subchapterName: String!) {
    getSiblingSubchapters(limit: $limit, chapterName: $chapterName, subchapterName: $subchapterName) {
      name
      label
      mastery
    }
  }
`;

/**
 * __useSiblingSubchaptersQuery__
 *
 * To run a query within a React component, call `useSiblingSubchaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiblingSubchaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiblingSubchaptersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      chapterName: // value for 'chapterName'
 *      subchapterName: // value for 'subchapterName'
 *   },
 * });
 */
export function useSiblingSubchaptersQuery(
  baseOptions: Apollo.QueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables> &
    ({ variables: SiblingSubchaptersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export type SiblingSubchaptersQueryHookResult = ReturnType<typeof useSiblingSubchaptersQuery>;
export type SiblingSubchaptersLazyQueryHookResult = ReturnType<typeof useSiblingSubchaptersLazyQuery>;
export type SiblingSubchaptersSuspenseQueryHookResult = ReturnType<typeof useSiblingSubchaptersSuspenseQuery>;
export type SiblingSubchaptersQueryResult = Apollo.QueryResult<
  SiblingSubchaptersQuery,
  SiblingSubchaptersQueryVariables
>;
export const SubchapterPrerequisitesDocument = gql`
  query SubchapterPrerequisites($subchapterName: String!, $limit: Int) {
    getSubchapterPrerequisites(subchapterName: $subchapterName, limit: $limit) {
      label
      name
      mastery
    }
  }
`;

/**
 * __useSubchapterPrerequisitesQuery__
 *
 * To run a query within a React component, call `useSubchapterPrerequisitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterPrerequisitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterPrerequisitesQuery({
 *   variables: {
 *      subchapterName: // value for 'subchapterName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSubchapterPrerequisitesQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables> &
    ({ variables: SubchapterPrerequisitesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export type SubchapterPrerequisitesQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesQuery>;
export type SubchapterPrerequisitesLazyQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesLazyQuery>;
export type SubchapterPrerequisitesSuspenseQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesSuspenseQuery>;
export type SubchapterPrerequisitesQueryResult = Apollo.QueryResult<
  SubchapterPrerequisitesQuery,
  SubchapterPrerequisitesQueryVariables
>;
export const TableOfContentsDocument = gql`
  query TableOfContents($learningNodeNames: [String]!, $hierarchyLabel: String!) {
    getTableOfContents(learningNodeNames: $learningNodeNames, hierarchyLabel: $hierarchyLabel) {
      name
      mastery
      id
      isRelevant
      parentName
      description
      completed
      index
    }
  }
`;

/**
 * __useTableOfContentsQuery__
 *
 * To run a query within a React component, call `useTableOfContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableOfContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableOfContentsQuery({
 *   variables: {
 *      learningNodeNames: // value for 'learningNodeNames'
 *      hierarchyLabel: // value for 'hierarchyLabel'
 *   },
 * });
 */
export function useTableOfContentsQuery(
  baseOptions: Apollo.QueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables> &
    ({ variables: TableOfContentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export type TableOfContentsQueryHookResult = ReturnType<typeof useTableOfContentsQuery>;
export type TableOfContentsLazyQueryHookResult = ReturnType<typeof useTableOfContentsLazyQuery>;
export type TableOfContentsSuspenseQueryHookResult = ReturnType<typeof useTableOfContentsSuspenseQuery>;
export type TableOfContentsQueryResult = Apollo.QueryResult<TableOfContentsQuery, TableOfContentsQueryVariables>;
export const TrackOverviewDocument = gql`
  query TrackOverview($topicName: String!) {
    getTrackOverview(topicName: $topicName) {
      name
      description
      objectives
      lessons {
        name
        description
      }
      skillRequirements
      otherRequirements
      otherRequirementsTitle
      prerequisites {
        name
        subfield
        field
        mastery
      }
    }
  }
`;

/**
 * __useTrackOverviewQuery__
 *
 * To run a query within a React component, call `useTrackOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackOverviewQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useTrackOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables> &
    ({ variables: TrackOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export type TrackOverviewQueryHookResult = ReturnType<typeof useTrackOverviewQuery>;
export type TrackOverviewLazyQueryHookResult = ReturnType<typeof useTrackOverviewLazyQuery>;
export type TrackOverviewSuspenseQueryHookResult = ReturnType<typeof useTrackOverviewSuspenseQuery>;
export type TrackOverviewQueryResult = Apollo.QueryResult<TrackOverviewQuery, TrackOverviewQueryVariables>;
export const UserOverallProgressDocument = gql`
  query UserOverallProgress {
    getUserOverallProgress {
      child {
        name
        label
      }
      parent {
        label
        name
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserOverallProgressQuery__
 *
 * To run a query within a React component, call `useUserOverallProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOverallProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOverallProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOverallProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export type UserOverallProgressQueryHookResult = ReturnType<typeof useUserOverallProgressQuery>;
export type UserOverallProgressLazyQueryHookResult = ReturnType<typeof useUserOverallProgressLazyQuery>;
export type UserOverallProgressSuspenseQueryHookResult = ReturnType<typeof useUserOverallProgressSuspenseQuery>;
export type UserOverallProgressQueryResult = Apollo.QueryResult<
  UserOverallProgressQuery,
  UserOverallProgressQueryVariables
>;
export const UserProgressDocument = gql`
  query UserProgress($nodeName: String!, $nodeLabel: NodeLabel!) {
    getUserProgress(nodeName: $nodeName, nodeLabel: $nodeLabel) {
      child {
        name
        label
        totalTracks
        completedTracks
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserProgressQuery__
 *
 * To run a query within a React component, call `useUserProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProgressQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useUserProgressQuery(
  baseOptions: Apollo.QueryHookOptions<UserProgressQuery, UserProgressQueryVariables> &
    ({ variables: UserProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export type UserProgressQueryHookResult = ReturnType<typeof useUserProgressQuery>;
export type UserProgressLazyQueryHookResult = ReturnType<typeof useUserProgressLazyQuery>;
export type UserProgressSuspenseQueryHookResult = ReturnType<typeof useUserProgressSuspenseQuery>;
export type UserProgressQueryResult = Apollo.QueryResult<UserProgressQuery, UserProgressQueryVariables>;
export const GetFirstSubchapterByTopicNameDocument = gql`
  query GetFirstSubchapterByTopicName($topicName: String!) {
    getFirstSubchapterByTopicName(topicName: $topicName) {
      success
      message
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
    }
  }
`;

/**
 * __useGetFirstSubchapterByTopicNameQuery__
 *
 * To run a query within a React component, call `useGetFirstSubchapterByTopicNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstSubchapterByTopicNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstSubchapterByTopicNameQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useGetFirstSubchapterByTopicNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  > &
    ({ variables: GetFirstSubchapterByTopicNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export type GetFirstSubchapterByTopicNameQueryHookResult = ReturnType<typeof useGetFirstSubchapterByTopicNameQuery>;
export type GetFirstSubchapterByTopicNameLazyQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameLazyQuery
>;
export type GetFirstSubchapterByTopicNameSuspenseQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameSuspenseQuery
>;
export type GetFirstSubchapterByTopicNameQueryResult = Apollo.QueryResult<
  GetFirstSubchapterByTopicNameQuery,
  GetFirstSubchapterByTopicNameQueryVariables
>;
export const GetLearningObjectiveByIdDocument = gql`
  query GetLearningObjectiveById($loId: ID!) {
    getLearningObjectiveById(loId: $loId) {
      success
      message
      id
      title
      description
      completed
    }
  }
`;

/**
 * __useGetLearningObjectiveByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectiveByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectiveByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectiveByIdQuery({
 *   variables: {
 *      loId: // value for 'loId'
 *   },
 * });
 */
export function useGetLearningObjectiveByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables> &
    ({ variables: GetLearningObjectiveByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export type GetLearningObjectiveByIdQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdQuery>;
export type GetLearningObjectiveByIdLazyQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdLazyQuery>;
export type GetLearningObjectiveByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectiveByIdSuspenseQuery
>;
export type GetLearningObjectiveByIdQueryResult = Apollo.QueryResult<
  GetLearningObjectiveByIdQuery,
  GetLearningObjectiveByIdQueryVariables
>;
export const GetLearningObjectivesBySubchapterDocument = gql`
  query GetLearningObjectivesBySubchapter($learningObjectiveId: ID!) {
    getLearningObjectivesBySubchapter(learningObjectiveId: $learningObjectiveId) {
      id
      title
      description
      index
      completed
    }
  }
`;

/**
 * __useGetLearningObjectivesBySubchapterQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectivesBySubchapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectivesBySubchapterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectivesBySubchapterQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetLearningObjectivesBySubchapterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  > &
    ({ variables: GetLearningObjectivesBySubchapterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >(GetLearningObjectivesBySubchapterDocument, options);
}
export type GetLearningObjectivesBySubchapterQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterQuery
>;
export type GetLearningObjectivesBySubchapterLazyQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterLazyQuery
>;
export type GetLearningObjectivesBySubchapterSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterSuspenseQuery
>;
export type GetLearningObjectivesBySubchapterQueryResult = Apollo.QueryResult<
  GetLearningObjectivesBySubchapterQuery,
  GetLearningObjectivesBySubchapterQueryVariables
>;
export const SubchapterByIdDocument = gql`
  query SubchapterById($name: String!) {
    getSubchapterById(name: $name) {
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
      totalMastery
    }
  }
`;

/**
 * __useSubchapterByIdQuery__
 *
 * To run a query within a React component, call `useSubchapterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterByIdQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSubchapterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables> &
    ({ variables: SubchapterByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export type SubchapterByIdQueryHookResult = ReturnType<typeof useSubchapterByIdQuery>;
export type SubchapterByIdLazyQueryHookResult = ReturnType<typeof useSubchapterByIdLazyQuery>;
export type SubchapterByIdSuspenseQueryHookResult = ReturnType<typeof useSubchapterByIdSuspenseQuery>;
export type SubchapterByIdQueryResult = Apollo.QueryResult<SubchapterByIdQuery, SubchapterByIdQueryVariables>;
export const GetMasteryDocument = gql`
  query GetMastery($learningObjectiveId: ID!) {
    getUserLearningObjectiveMastery(learningObjectiveId: $learningObjectiveId) {
      learningObjectiveId
      userId
      totalMastery
    }
  }
`;

/**
 * __useGetMasteryQuery__
 *
 * To run a query within a React component, call `useGetMasteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasteryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasteryQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetMasteryQuery(
  baseOptions: Apollo.QueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables> &
    ({ variables: GetMasteryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasteryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasterySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export type GetMasteryQueryHookResult = ReturnType<typeof useGetMasteryQuery>;
export type GetMasteryLazyQueryHookResult = ReturnType<typeof useGetMasteryLazyQuery>;
export type GetMasterySuspenseQueryHookResult = ReturnType<typeof useGetMasterySuspenseQuery>;
export type GetMasteryQueryResult = Apollo.QueryResult<GetMasteryQuery, GetMasteryQueryVariables>;
export const ExportCsvDocument = gql`
  query ExportCsv {
    exportCsv {
      success
      csvData
      message
    }
  }
`;

/**
 * __useExportCsvQuery__
 *
 * To run a query within a React component, call `useExportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export type ExportCsvQueryHookResult = ReturnType<typeof useExportCsvQuery>;
export type ExportCsvLazyQueryHookResult = ReturnType<typeof useExportCsvLazyQuery>;
export type ExportCsvSuspenseQueryHookResult = ReturnType<typeof useExportCsvSuspenseQuery>;
export type ExportCsvQueryResult = Apollo.QueryResult<ExportCsvQuery, ExportCsvQueryVariables>;
export const SchoolsDocument = gql`
  query Schools {
    getSchools {
      id
      providerName
      teamName
      icon
    }
  }
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsSuspenseQueryHookResult = ReturnType<typeof useSchoolsSuspenseQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const DeleteTodoDocument = gql`
  mutation DeleteTodo($id: String!) {
    deleteTodo(id: $id) {
      success
      message
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<DeleteTodoMutation, DeleteTodoMutationVariables>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTodoMutation, DeleteTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(DeleteTodoDocument, options);
}
export type DeleteTodoMutationHookResult = ReturnType<typeof useDeleteTodoMutation>;
export type DeleteTodoMutationResult = Apollo.MutationResult<DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<DeleteTodoMutation, DeleteTodoMutationVariables>;
export const RecentTodosDocument = gql`
  query RecentTodos {
    recentTodos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useRecentTodosQuery__
 *
 * To run a query within a React component, call `useRecentTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentTodosQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export type RecentTodosQueryHookResult = ReturnType<typeof useRecentTodosQuery>;
export type RecentTodosLazyQueryHookResult = ReturnType<typeof useRecentTodosLazyQuery>;
export type RecentTodosSuspenseQueryHookResult = ReturnType<typeof useRecentTodosSuspenseQuery>;
export type RecentTodosQueryResult = Apollo.QueryResult<RecentTodosQuery, RecentTodosQueryVariables>;
export const TodosDocument = gql`
  query Todos {
    todos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodosQuery(baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosSuspenseQueryHookResult = ReturnType<typeof useTodosSuspenseQuery>;
export type TodosQueryResult = Apollo.QueryResult<TodosQuery, TodosQueryVariables>;
export const GetActiveTracksDocument = gql`
  query GetActiveTracks {
    getActiveTracks {
      name
      description
      last_modified
      totalUnits
      completedUnits
      parentSubfield
      parentFields
      mastery
      imageUrl
    }
  }
`;

/**
 * __useGetActiveTracksQuery__
 *
 * To run a query within a React component, call `useGetActiveTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export type GetActiveTracksQueryHookResult = ReturnType<typeof useGetActiveTracksQuery>;
export type GetActiveTracksLazyQueryHookResult = ReturnType<typeof useGetActiveTracksLazyQuery>;
export type GetActiveTracksSuspenseQueryHookResult = ReturnType<typeof useGetActiveTracksSuspenseQuery>;
export type GetActiveTracksQueryResult = Apollo.QueryResult<GetActiveTracksQuery, GetActiveTracksQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      id
      name
      username
      gender
      is_onboarded
      modal_onboarding
      isPro
      trialEndDate
      audioOn
      phoneNumber
      isEmailVerified
      current_weight_lbs
      goal_weight_lbs
      googleCalendarAuthToken
      isCareerLoading
      enrolledTracks
      otpPreference
      countryCode
      fullPhoneNumber
      email
      dailyEmailOptIn
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
