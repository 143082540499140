import { GestureHandlerRootView } from "react-native-gesture-handler";
import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { AppConfigProvider } from "./AppConfigProvider";

import { ApolloProvider } from "@apollo/client";
import { AppStateProvider } from "./contexts/AppStateContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import "react-native-reanimated";
import { Platform } from "react-native";
import { loadFonts } from "./expo-fonts";
import { PaperProvider } from "react-native-paper";
import { PostHogProvider } from "posthog-js/react";
import AppNavigator from "./navigation/AppNavigator.web";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserContext";
import { WindowDimensionsProvider } from "./contexts/WindowDimensionsContext";
import Loader from "./components/common/status/Loader/Loader";
import { AudioPlayerProvider } from "./contexts/AudioPlayerContext";
import { MathJaxContext } from "better-react-mathjax";
import { apolloClient } from "./graphql/graphql.client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import axios from "axios";

import "./styles.web.css";
import { ChatBarProvider } from "./contexts/ChatBarContext";
import { ChatBarInputProvider } from "./contexts/CharBarInputContext";
import { AccessibilityProvider } from "./contexts/AccessibilityContext";
import Sentry from "./utils/sentry";

if (Platform.OS === "web") {
  global._frameTimestamp = null;
}

const options = {
  api_host: "https://app.posthog.com",
  session_recording: {
    maskTextSelector: '[data-sensitive="true"]',
  },
};

const App = () => {
  const [isReady, setIsReady] = useState(false);
  const [currentDomain, setCurrentDomain] = useState(window.location.host);
  const [appConfig, setAppConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const R = window;
      const a = document;
      const p = "https://js.rapidr.io";
      const i = "sdk.js";

      R.Rapidr =
        R.Rapidr ||
        function () {
          (R.Rapidr.q = R.Rapidr.q || []).push(arguments);
        };

      const d = a.getElementsByTagName("head")[0];
      const r = a.createElement("script");
      r.async = 1;
      r.src = p + "/" + i;
      d.appendChild(r);
    }
  }, []);

  useEffect(() => {
    loadFonts().then(() => setIsReady(true));
  }, []);

  // Fetching the domain from AsyncStorage
  useEffect(() => {
    const fetchDomain = async () => {
      try {
        const savedDomain = await AsyncStorage.getItem("domain");
        if (savedDomain) {
          setCurrentDomain(savedDomain);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    };
    fetchDomain();
  }, []);

  useEffect(() => {
    const fetchAppConfig = async () => {
      setIsLoading(true);
      try {
        let url = `https://${currentDomain}/config`;

        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            "X-User-Domain": currentDomain,
          },
        });

        setAppConfig(response.data);
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentDomain) {
      fetchAppConfig();
    }
  }, [currentDomain]);

  useEffect(() => {
    if (!appConfig || !appConfig.metaTags) {
      return; // Exit the effect if appConfig or metaTags are not available
    }

    // Update document title
    document.title = appConfig.metaTags.title;
  }, [appConfig]);

  if (!isReady || isLoading || !appConfig.companionName) {
    return (
      <View style={styles.loaderContainer}>
        <Loader />
      </View>
    );
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <I18nextProvider i18n={i18n}>
        <AppConfigProvider currentDomain={currentDomain} appConfig={appConfig}>
          <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options} autocapture>
            <ApolloProvider client={apolloClient}>
              <AppStateProvider>
                <AuthProvider apolloClient={apolloClient}>
                  <UserProvider>
                    <PaperProvider>
                      <WebSocketProvider>
                        <WindowDimensionsProvider>
                          <AccessibilityProvider>
                            <AudioPlayerProvider>
                              <MathJaxContext>
                                <ChatBarInputProvider>
                                  <ChatBarProvider>
                                    <AppNavigator />
                                  </ChatBarProvider>
                                </ChatBarInputProvider>
                              </MathJaxContext>
                            </AudioPlayerProvider>
                          </AccessibilityProvider>
                        </WindowDimensionsProvider>
                      </WebSocketProvider>
                    </PaperProvider>
                  </UserProvider>
                </AuthProvider>
              </AppStateProvider>
            </ApolloProvider>
          </PostHogProvider>
        </AppConfigProvider>
      </I18nextProvider>
    </GestureHandlerRootView>
  );
};

let AppEntryPoint = App;

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default Sentry.wrap(AppEntryPoint);
