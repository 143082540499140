import { useRoute } from "@react-navigation/native";
import { useAuth } from "../hooks/useAuth";
import BlankLayout from "../layouts/BlankLayout";
import Loader from "../components/common/status/Loader/Loader";

function LoginLTIView() {
  const route = useRoute();
  const { login } = useAuth();

  if (route.params?.token) {
    login(route.params.token);

    if (route.params?.redirect) {
      window.location.href = route.params.redirect;
    } else {
      window.location.href = "/";
    }
  }

  return (
    <BlankLayout>
      <Loader />
    </BlankLayout>
  );
}

export default LoginLTIView;
