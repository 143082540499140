import React, { createContext, useContext } from "react";

export type DomainConfig = {
  metaTags: {
    title: string;
    description: string;
    themeColor: string;
    appleTouchIcon: string;
    twitterCard: string;
    twitterSite: string;
    twitterTitle: string;
    twitterDescription: string;
    twitterImage: string;
  };
  externalIntegrations: {
    stripe: {
      stripeSubscriptionId: string;
      hasTrial: boolean;
      trialDays: number;
    };
    sendGrid: {
      appInviteTemplateId: string;
      dailyEmailTemplateId: string;
      fromEmail: string;
      fromName: string;
      twilioServiceSID: string;
    };
  };
  companionName: string;
  key: string;
  isLMS: boolean;
  isLTI: boolean;
  isMoodleOffering: boolean;
  primaryColor: string;
  learningFieldColor: string;
  learningSubFieldColor: string;
  gameMenuBg: string;
  gameMenuTrack: string;
  gameMenuRefresh: string;
  gameMenuRefreshText: string;
  gameMenuProgress: string;
  gameMenuCardBg: string;
  gameMenuCardButton: string;
  gameMenuCardButtonText: string;
  gameMenuCardRefresh: string;
  emulationStatement: string;
  learningEmulationStatement: string;
  logo: string;
  showMiniLogo: boolean;
  scope: string[];
  additionalScope?: { name: string; externalURL: string; index: number }[];
  connectURL: string;
  termsOfServiceLink: string;
  showObjectivesTab: boolean;
  showJobsTab: boolean;
  generalPrompt: string;
  onboardingPrompt: string;
  newsSectionTitle: string;
  customNews: boolean;
  showNews: boolean;
  newsSearchTerms: string;
  showInviteFriends: boolean;
  showFeedback: boolean;
  isPrivateLabelIcon: boolean;
  bugReportEmails: string;
  upgradeModalBody: string;
  isFreemium: boolean;
  hasEmailVerification: boolean;
};

type ExtendedDomainConfig = DomainConfig & {
  currentDomain: string;
};

// Create a Context
const AppConfigContext = createContext<ExtendedDomainConfig | null>(null);

export const useAppConfig = () => useContext(AppConfigContext);

export const AppConfigProvider = ({
  children,
  currentDomain,
  appConfig,
}: {
  children: React.ReactNode;
  currentDomain: string;
  appConfig: DomainConfig;
}) => {
  // No need for useState for appConfig or isLoading here

  const value = {
    ...appConfig,
    currentDomain,
  };

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};
