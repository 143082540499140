import React from "react";
import { Pressable, Text, View, StyleSheet } from "react-native";
import { useAppConfig } from "../../../../AppConfigProvider";
import { primaryLightened } from "../../../../utils/color";
import { useTranslation } from "react-i18next";
import { shadowStyles } from "../../../../styles/shadow";

const CustomButton = ({
  text,
  onPress,
  styleType = "light",
  size = "m",
  leftIcon,
  rightIcon,
  style,
  textStyle,
  disabled,
  bold = false,
  textProps,
  useTranslationText = true,
  ...props
}) => {
  const { t } = useTranslation();
  const { primaryColor } = useAppConfig();
  const getButtonStyle = buttonColors[styleType] || (() => ({}));
  const getButtonSize = buttonSizes[size] || buttonSizes.m;
  const getButtonSpacing = buttonSpacing(leftIcon || rightIcon);
  const getTextStyle = textColors[styleType] || (() => ({}));
  const getTextSize = textSizes[size] || textSizes.m;

  const combinedButtonStyle = [
    styles.button,
    shadowStyles.primary,
    getButtonStyle(primaryColor),
    getButtonSize,
    getButtonSpacing,
    style,
  ];

  const combinedTextStyle = [
    styles.text,
    getTextStyle(primaryColor),
    getTextSize,
    bold ? styles.bold : styles.medium,
    textStyle,
  ];

  // Conditional text rendering based on the useTranslationText prop
  const buttonText = useTranslationText ? (text ? t(`buttons.${text}`) : "") : text;

  return (
    <Pressable role="button" onPress={onPress} style={combinedButtonStyle} disabled={disabled} {...props}>
      {leftIcon && <View>{leftIcon}</View>}
      <Text style={combinedTextStyle} {...textProps}>
        {buttonText}
      </Text>
      {rightIcon && <View>{rightIcon}</View>}
    </Pressable>
  );
};

export default CustomButton;

const styles = StyleSheet.create({
  button: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontSize: 14,
    fontFamily: "inter",
  },
  bold: {
    fontWeight: "bold",
  },
  medium: {
    fontWeight: "500",
  },
});

const buttonColors = {
  light: () => ({ backgroundColor: "#fff" }),
  dark: () => ({ backgroundColor: "#000" }),
  danger: () => ({ backgroundColor: "#F75C8A" }),
  transparent: () => ({
    backgroundColor: "transparent",
    shadowColor: "transparent",
    elevation: 0,
  }),
  lightTransparent: () => ({
    backgroundColor: "transparent",
    shadowColor: "transparent",
    elevation: 0,
  }),
  primary: (primaryColor) => ({ backgroundColor: primaryColor }),
  primaryLightened: (primaryColor) => ({
    backgroundColor: primaryLightened(primaryColor),
    shadowColor: "transparent",
    elevation: 0,
  }),
  primaryLight: () => ({ backgroundColor: "#fff" }),
  primaryTransparent: () => ({
    backgroundColor: "transparent",
    shadowColor: "transparent",
    elevation: 0,
  }),
  lightGreen: () => ({ backgroundColor: "#66bb6a" }),
};

const buttonSizes = {
  xs: { padding: 4, borderRadius: 4 },
  s: { padding: 8, borderRadius: 8 },
  m: { padding: 12, borderRadius: 12 },
  l: { padding: 16, borderRadius: 16 },
  xl: { padding: 20, borderRadius: 20 },
  xxl: { padding: 24, borderRadius: 24 },
};

const buttonSpacing = (icon) => ({
  justifyContent: icon ? "space-between" : "center",
});

const textColors = {
  light: () => ({ color: "#000" }),
  dark: () => ({ color: "#fff" }),
  danger: () => ({ color: "#fff" }),
  transparent: () => ({ color: "#000" }),
  lightTransparent: () => ({ color: "#fff" }),
  primary: () => ({ color: "#fff" }),
  primaryLightened: () => ({ color: "#000" }),
  primaryLight: (primaryColor) => ({
    color: primaryColor,
  }),
  primaryTransparent: (primaryColor) => ({ color: primaryColor }),
  lightGreen: () => ({ color: "#fff" }),
};

const textSizes = {
  xs: { fontSize: 12 },
  s: { fontSize: 14 },
  m: { fontSize: 16 },
  l: { fontSize: 18 },
  xl: { fontSize: 20 },
  xxl: { fontSize: 22 },
};
