import { useEffect, useState, useCallback } from "react";
import { View, StyleSheet, Linking } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import AsyncStorage from "@react-native-async-storage/async-storage";
import BackButton from "../components/navigation/BackButton/BackButton";
import RoundedLabel from "../components/common/label/RoundedLabel/RoundedLabel";
import { useNavigation, useRoute } from "@react-navigation/native";
import { MotiView } from "moti";
import { getAnnouncement, getAssignment, getPage, getSubmissions, searchWeb } from "../services";
import FileCard from "../components/lms/FileCard/FileCard";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { formatDate } from "../utils/date";
import CustomHtml from "../components/common/general/CustomHtml/CustomHtml";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { useChatBar } from "../contexts/ChatBarContext";
import SourcesList from "../components/lms/SourcesList/SourcesList";
import ImageCardList from "../components/lms/ImageCardList/ImageCardList";
import VideoCardList from "../components/lms/VideoCardList/VideoCardList";
import Divider from "../components/common/layout/Divider/Divider";
import Loader from "../components/common/status/Loader/Loader";
import { useAppConfig } from "../AppConfigProvider";
import useWebSocket from "../hooks/useWebSocket";
import Sentry from "../utils/sentry";
import { useGetChatsQuery } from "../graphql/generated/graphql";
import { shadowStyles } from "../styles/shadow";

function ResourceView() {
  const [resource, setResource] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSidebarData, setIsLoadingSidebarData] = useState(true);
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params;
  const resourceType = params.resource_type;
  const resourceId = params.resource_id;
  const courseId = params.course_id;
  const integrationId = params.integration_id;
  const { setShowChat, mapRouteToContext, setConversationType, setSchoolMessage, setMetaIsLoading } = useChatBar();
  const { isMedium } = useResponsiveScreen();
  const [grade, setGrade] = useState("ungraded");
  const appConfig = useAppConfig();
  const isMoodleOffering = appConfig.isMoodleOffering;
  const isSuggestedInsights = appConfig.isSuggestedInsights;
  const { sendMessage } = useWebSocket();
  const [videoCardData, setVideoCardData] = useState([]);
  const [sources, setSources] = useState([]);
  const [imageCardData, setImageCardData] = useState([]);
  const { data: chatsData } = useGetChatsQuery();
  const [chatExists, setChatExists] = useState(false);

  useEffect(() => {
    setConversationType("SCHOOL");
    setSchoolMessage({
      contentType: resourceType,
      contentId: resourceId,
      classId: courseId,
      integrationId,
    });
  }, [resourceId, courseId, integrationId, resourceType]);

  const checkChatExists = useCallback(() => {
    if (chatsData?.getChats) {
      const existingChat = chatsData.getChats.find(
        (chat) => chat.objectId === resourceId && chat.context === "assignment"
      );
      setChatExists(!!existingChat);
    }
  }, [chatsData, resourceId]);

  useEffect(() => {
    checkChatExists();
  }, [checkChatExists]);

  const fetchResourceData = async () => {
    setIsLoading(true);
    const token = await AsyncStorage.getItem("token");

    let data;
    if (resourceType === "page") {
      data = await getPage(token, integrationId, courseId, resourceId);
    } else if (resourceType === "assignment") {
      data = await getAssignment(token, integrationId, courseId, resourceId);
      const points_possible = data.points_possible;
      const submission = await getSubmissions(token, integrationId, courseId, resourceId);
      const gradePoints = submission.$data[0]?.grade_points;
      if (!gradePoints) {
        setGrade("Ungraded");
      } else {
        const percentage = (gradePoints / points_possible) * 100;
        setGrade(`${percentage.toFixed(2)}%`);
      }
    } else if (resourceType === "announcement") {
      data = await getAnnouncement(token, integrationId, courseId, resourceId);
    }

    setResource(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchResourceData();
  }, [integrationId, courseId, resourceId, resourceType]);

  useEffect(() => {
    if (resource.title) {
      const fetchData = async () => {
        try {
          setIsLoadingSidebarData(true);
          const token = await AsyncStorage.getItem("token");
          const data = await searchWeb(resource.id, token);

          const videos = data.video.map((item) => ({
            imageUrl: item.metadata.videoThumbnail,
            title: item.metadata.title,
            url: item.metadata.source,
          }));

          // Update to only use the first image from the data
          const images = data.images.flatMap((item) =>
            item.metadata.images.slice(0, 1).map((image) => ({
              imageUrl: image,
              title: item.metadata.title,
              source: item.metadata.source,
            }))
          );

          const wikipedia = data.Wikipedia.map((item) => ({
            content: item.pageContent,
            title: item.metadata.title,
            source: item.metadata.source,
          }));

          setVideoCardData(videos);
          setImageCardData(images);
          setSources(wikipedia);
          setIsLoadingSidebarData(false);
        } catch (error) {
          Sentry.captureException(error);
          setIsLoadingSidebarData(false);
        }
      };

      fetchData();
    }
  }, [resource.title]);

  const navigateToLMS = () => {
    Linking.openURL(resource.url);
  };

  const isAnnouncement = resourceType === "announcement";

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");

      let data;
      if (resourceType === "page") {
        data = await getPage(token, integrationId, courseId, resourceId);
      } else if (resourceType === "assignment") {
        data = await getAssignment(token, integrationId, courseId, resourceId);
        const points_possible = data.points_possible;
        const submission = await getSubmissions(token, integrationId, courseId, resourceId);
        const gradePoints = submission.$data[0]?.grade_points;
        if (!gradePoints) {
          setGrade("Ungraded");
        } else {
          const percentage = (gradePoints / points_possible) * 100;
          setGrade(`${percentage.toFixed(2)}%`);
        }
      } else if (isAnnouncement) {
        data = await getAnnouncement(token, integrationId, courseId, resourceId);
      }

      setResource(data);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationId, courseId, resourceId, resourceType]);

  const handleBackPress = () => {
    navigation.navigate("Course", {
      course_id: courseId,
      integration_id: integrationId,
    });
  };

  const handleGetHelpFromAxio = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoading: true,
      },
      state: {
        general: "SCHOOL",
        context: mapRouteToContext(route.name),
        params: route.params,
        school: {
          contentType: resourceType,
          contentId: resourceId,
          classId: courseId,
          integrationId,
        },
      },
      content: {
        message: chatExists ? "Let’s continue where we left off" : "Help me get started with this topic",
        author: "HUMAN",
        type: null,
      },
    });
    setMetaIsLoading(true);
    setShowChat(true);
  };

  const labelText =
    resourceType === "announcement"
      ? "Announcement"
      : resourceType === "assignment"
        ? isMoodleOffering
          ? "Activity"
          : "Assignment"
        : resourceType === "page"
          ? "Material"
          : "Resource";

  const titleText = isAnnouncement ? "Description" : "Instructions";

  const renderSidebar = () => {
    const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;

    if (!hasData) {
      return null;
    }

    return (
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={10}
        style={styles.sidebarContainer}
        transition={{ type: "timing", duration: 600 }}>
        {sources.length > 0 && (
          <>
            <CustomText text="Sources" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <SourcesList sources={sources} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {imageCardData.length > 0 && (
          <>
            <CustomText text="Images" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <ImageCardList imageCardData={imageCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}

        {videoCardData.length > 0 && (
          <>
            <CustomText text="Videos" style={styles.headerText} size="m" weight="bold" role="heading" aria-level="2" />
            <VideoCardList videoCardData={videoCardData} />
            <CustomSpacing type="vertical" size="m" />
          </>
        )}
      </MotiView>
    );
  };

  return (
    <DefaultLayout>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={isMedium ? styles.mainContainer : styles.mainContainerMobile}>
        {isLoading ? (
          <View style={styles.loaderContainer}></View>
        ) : (
          <>
            <View style={isMedium ? styles.rowContainer : styles.columnContainer}>
              <View style={isMedium ? styles.contentContainer : styles.fullWidthContentContainer}>
                {isMedium ? (
                  <>
                    <MotiView
                      from={{
                        opacity: 0,
                        translateY: 20,
                        scale: 1,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        scale: 1,
                      }}
                      delay={10}
                      transition={{ type: "timing", duration: 600 }}>
                      <View style={styles.headerContainer}>
                        <BackButton onPress={handleBackPress} />
                        <CustomText useTranslationText={false} text={resource?.title} size="xs" textType="display" />
                      </View>

                      <Divider type="vertical" />

                      <View style={styles.headerContainer}>
                        <RoundedLabel text={labelText} styleType="highlight" />
                        <CustomSpacing type="horizontal" size="l" />

                        <View style={styles.detailRow}>
                          <CustomText text="Due " weight="bold" size="xs" />

                          <CustomText
                            useTranslationText={false}
                            text={formatDate(
                              isAnnouncement ? resource?.created_date : resource?.due_date,
                              !isAnnouncement
                            )}
                            size="xs"
                          />
                        </View>

                        <CustomSpacing type="horizontal" size="l" />
                        {/* Grade Display */}
                        {resourceType === "assignment" && (
                          <>
                            <View style={styles.detailRow}>
                              <CustomText text="Grade " weight="bold" size="xs" />
                              <CustomText text={grade} useTranslationText={false} size="xs" />
                            </View>
                            <CustomSpacing type="horizontal" size="l" />
                            <CustomButton
                              text={chatExists ? "Continue Help From Axio" : "Get Help From Axio"}
                              styleType="primaryLight"
                              bold={true}
                              size="xs"
                              onPress={handleGetHelpFromAxio}
                              style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                              aria-label="Get help from Axio"
                            />
                            <CustomSpacing type="horizontal" size="l" />
                          </>
                        )}

                        {!isAnnouncement && (
                          <CustomButton
                            text="Submit"
                            styleType="primaryLight"
                            bold={true}
                            size="xs"
                            onPress={navigateToLMS}
                            style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                            aria-label="Submit assignment"
                          />
                        )}
                        <CustomSpacing type="horizontal" size="l" />
                      </View>

                      <Divider type="vertical" />
                    </MotiView>
                  </>
                ) : (
                  <>
                    <MotiView
                      from={{
                        opacity: 0,
                        translateY: 20,
                        scale: 1,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        scale: 1,
                      }}
                      delay={10}
                      transition={{ type: "timing", duration: 600 }}>
                      <View style={styles.headerContainer}>
                        <BackButton onPress={handleBackPress} />
                        <CustomText useTranslationText={false} text={resource?.title} size="m" weight="bold" />
                      </View>
                      <CustomSpacing type="vertical" size="s" />

                      <View style={styles.mobileRow}>
                        <RoundedLabel text={labelText} styleType="highlight" />
                        <CustomSpacing type="horizontal" size="l" />

                        <View style={styles.detailRow}>
                          <CustomText text="Due " weight="bold" size="xs" />

                          <CustomText
                            useTranslationText={false}
                            text={formatDate(
                              isAnnouncement ? resource?.created_date : resource?.due_date,
                              !isAnnouncement
                            )}
                            size="xs"
                          />
                        </View>

                        <CustomSpacing type="horizontal" size="l" />
                        {/* Grade Display */}
                        {resourceType === "assignment" && (
                          <>
                            <View style={styles.detailRow}>
                              <CustomText text="Grade " weight="bold" size="xs" />

                              <CustomText text={grade} size="xs" />
                            </View>
                            <CustomSpacing type="horizontal" size="l" />
                          </>
                        )}

                        {!isAnnouncement && (
                          <CustomButton
                            text="Submit"
                            styleType="primaryLight"
                            bold={true}
                            size="xs"
                            onPress={navigateToLMS}
                            style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                            aria-label="Submit assignment"
                          />
                        )}
                        <CustomSpacing type="horizontal" size="l" />
                        {/* <CustomButton
                          text="Get Tutoring"
                          styleType="primaryLight"
                          bold={true}
                          size="xs"
                          onPress={null} //TODO Trigger chat here.
                          style={[styles.buttonBorder, shadowStyles.primary, { marginTop: 10 }]}
                        /> */}
                      </View>
                    </MotiView>
                  </>
                )}
                <CustomSpacing type="vertical" size="l" />

                <MotiView
                  from={{
                    opacity: 0,
                    translateY: 20,
                    scale: 1,
                  }}
                  animate={{
                    opacity: 1,
                    translateY: 0,
                    scale: 1,
                  }}
                  delay={600}
                  transition={{ type: "timing", duration: 600 }}
                  // style={{ flex: 1 }}
                >
                  <CustomText
                    useTranslationText={false}
                    text={titleText}
                    size="m"
                    weight="bold"
                    role="header"
                    aria-level="2"
                  />
                  <CustomSpacing type="vertical" size="s" />
                  <View>
                    {isMoodleOffering ? (
                      <CustomHtml html={'To get started with this activity type or press "Get Started"'} />
                    ) : (
                      <CustomHtml
                        html={(isAnnouncement ? resource?.body : resource?.description) || "No instructions"}
                      />
                    )}
                  </View>
                </MotiView>

                {resource?.attachments.length > 0 && (
                  <>
                    <CustomSpacing type="vertical" size="s" />
                    <CustomText text={"Attachments"} size="m" weight="bold" role="header" aria-level="2" />

                    <CustomSpacing type="vertical" size="s" />
                    <View style={styles.scrollViewWrapper}>
                      <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        style={styles.fileCardsScrollView}
                        contentContainerStyle={{
                          gap: 15,
                        }}>
                        {resource?.attachments.map((attachment) => (
                          <FileCard key={attachment.url} title={attachment?.title} url={attachment?.url} />
                        ))}
                      </ScrollView>
                    </View>
                    <CustomSpacing type="vertical" size="l" />
                  </>
                )}
              </View>

              {isSuggestedInsights && (
                <>
                  {isMedium ? (
                    <>
                      <View style={[{ width: 300 }, isLoadingSidebarData ? { alignItems: "center" } : {}]}>
                        <CustomText
                          style={{ margin: 12 }}
                          textType="display"
                          text="Suggested Insights"
                          size="xs"
                          bold={false}
                          role="heading"
                          aria-level="1"
                        />
                        {isLoadingSidebarData ? (
                          <View styles={styles.insightsCenterView}>
                            <Loader />
                          </View>
                        ) : (
                          <>{renderSidebar()}</>
                        )}
                      </View>
                    </>
                  ) : (
                    <>
                      <Divider size="l" />
                      <CustomText
                        textType="display"
                        text="Suggested Insights"
                        size="xs"
                        bold={true}
                        weight="bold"
                        role="heading"
                        aria-level="1"
                      />
                      <View style={isLoadingSidebarData ? { alignItems: "center" } : {}}>
                        {isLoadingSidebarData ? (
                          <View style={styles.fullWidthContentContainer}>
                            <Loader />
                          </View>
                        ) : (
                          <>{renderSidebar()}</>
                        )}
                      </View>
                    </>
                  )}
                </>
              )}
            </View>
          </>
        )}
      </ScrollView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  mainContainerMobile: {
    height: 20,
  },
  mainContainer: {
    // flex: 1,
    height: 20,
  },
  rowContainer: {
    flexDirection: "row",
    flex: 1,
  },
  columnContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fullWidthContentContainer: {
    padding: 16,
    // height: 20,
  },
  contentContainer: {
    flex: 3, // Takes 3/4 of the width
    padding: 16,
  },
  headerText: {
    marginBottom: 6,
  },
  sidebarContainer: {
    flex: 1, // Takes 1/4 of the width
    justifyContent: "flex-start",
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  mobileRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fileCardsScrollView: {
    flex: 1,
  },
  scrollViewWrapper: {
    height: 85,
  },
  detailRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  buttonBorder: {
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
  },
  insightsCenterView: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ResourceView;
