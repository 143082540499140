import React from "react";
import { View, StyleSheet } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import PriotityIndicator from "../PriorityIndicator/PriorityIndicator";
import Calendar from "../../svgs/common/Calendar";
import Edit from "../../svgs/common/Edit";
import Delete from "../../svgs/common/Delete";

function formatDateString(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return `${day}th`;
  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

function getRecurringText(isRecurring, frequency, dayOfWeek, dayOfMonth) {
  if (!isRecurring) {
    return "";
  }

  switch (frequency) {
    case "Day":
      return "Every Day";
    case "Week":
      return `Every Week on ${dayOfWeek}`;
    case "Month":
      return `Every Month on the ${getOrdinalSuffix(dayOfMonth)}`;
    default:
      return "";
  }
}

function TaskDetails({
  title,
  description,
  priority,
  startDate,
  isRecurring,
  recurringFrequency,
  recurringDayOfWeek,
  recurringDayOfMonth,
  onEdit,
  onDelete,
  onComplete,
  isComplete,
}) {
  const { isMedium } = useResponsiveScreen();
  const recurringText = getRecurringText(isRecurring, recurringFrequency, recurringDayOfWeek, recurringDayOfMonth);

  return (
    <View style={{ paddingTop: 10, paddingHorizontal: 5 }}>
      <CustomText text="Task Name" weight="ultraBold" />
      <CustomSpacing type="vertical" size="s" />

      <CustomText useTranslationText={false} text={title} />
      <CustomSpacing type="vertical" size="m" />

      {description && (
        <>
          <CustomText text="Description" weight="ultraBold" />
          <CustomSpacing type="vertical" size="s" />
          <CustomText useTranslationText={false} text={description} style={{ color: "#667085" }} />
          <CustomSpacing type="vertical" size="l" />
        </>
      )}

      <View
        style={{
          flexDirection: isMedium ? "row" : "column",
          justifyContent: "space-between",
        }}>
        <View>
          <CustomText text="Task Priority" weight="ultraBold" />
          <CustomSpacing type="vertical" size={isMedium ? "l" : "m"} />

          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <PriotityIndicator priority={priority} />
            <CustomSpacing type="horizontal" size="m" />
            {isMedium && <CustomText text={capitalizeFirstLetter(priority)} size="m" />}
          </View>
        </View>
        <CustomSpacing type="horizontal" size="m" />

        <View>
          {!isMedium && <CustomSpacing type="vertical" size="m" />}

          <CustomText text="Task Due Date" weight="ultraBold" />
          <CustomSpacing type="vertical" size={isMedium ? "l" : "m"} />

          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Calendar />
            <CustomText
              useTranslationText={false}
              text={formatDateString(startDate.toLocaleDateString())}
              size="m"
              style={{ marginLeft: 5 }}
            />
          </View>
        </View>
        <CustomSpacing type="horizontal" size="m" />
        {isRecurring && (
          <View>
            {!isMedium && <CustomSpacing type="vertical" size="m" />}
            <CustomSpacing type="horizontal" size="m" />
            <CustomText text="This Task Repeats" weight="ultraBold" />
            <CustomSpacing type="vertical" size="l" />
            <CustomText useTranslationText={false} text={recurringText} size="m" />
          </View>
        )}
      </View>

      <CustomSpacing type="vertical" size="l" />

      <CustomButton
        text="Edit Task"
        styleType="primary"
        onPress={onEdit}
        bold={true}
        leftIcon={<Edit style={{ marginRight: 7 }} />}
        style={{
          width: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
        aria-label="Edit task"
      />

      {isComplete && (
        <View>
          <CustomSpacing type="vertical" size="l" />

          <CustomButton
            text="Complete Task"
            onPress={onComplete}
            styleType="lightGreen" // Use the new light green style
            size="m"
            bold={true}
            style={styles.completeButton}
            aria-label="Mark as complete"
            accessibilityHint="Press to mark task as complete"
          />
        </View>
      )}

      {!isRecurring && !isComplete && (
        <View>
          <CustomSpacing type="vertical" size="l" />

          <CustomButton
            text="Mark as Incomplete"
            onPress={onComplete}
            styleType="danger" // Use the new light green style
            size="m"
            bold={true}
            style={styles.completeButton}
            aria-label="Mark as incomplete"
            accessibilityHint="Press to mark task as incomplete"
          />
        </View>
      )}
      <CustomSpacing type="vertical" size="l" />

      <CustomButton
        text="Delete Task"
        styleType="secondary"
        onPress={onDelete}
        textStyle={{ color: "#f63d67" }}
        bold={true}
        leftIcon={<Delete style={{ marginRight: 7 }} />}
        style={styles.noShadow}
        aria-label="Delete task"
        accessibilityHint="Press to delete task"
      />
    </View>
  );
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default TaskDetails;

const styles = StyleSheet.create({
  noShadow: {
    color: "red",
    width: "auto",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "transparent",
    elevation: 0,
  },
});
