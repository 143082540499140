import React, { useState } from "react";
import { View, TextInput, StyleSheet, Pressable } from "react-native";
import { SubmitIcon, WaveForm, WaveFormClose, AudioOn, AudioOff, ExpandChatIcon } from "../../svgIcons";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import QuickOptionListContainer from "../../../containers/chat/QuickOptionListContainer/QuickOptionListContainer";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { useAccessibilityContext } from "../../../contexts/AccessibilityContext";
import { shadowStyles } from "../../../styles/shadow";

function ChatBar({
  value,
  isRecording,
  isAudioOn,
  placeholder,
  inputRef,
  onChangeText,
  onSubmitEditing,
  onAudioOffPress,
  onAudioOnPress,
  onRecordingStopPress,
  onRecordingStartPress,
  onToggleChatPress,
  onQuickOptionPress,
  showChatWindow,
}) {
  const { isMedium } = useResponsiveScreen();
  const { screenReaderIsEnabled } = useAccessibilityContext();

  const INITIAL_HEIGHT = isMedium ? 48 : 36;

  const [inputHeight, setInputHeight] = useState(INITIAL_HEIGHT);

  const borderRadius = inputHeight < 50 ? 100 : 25;

  const handleContentSizeChange = (event) => {
    const newHeight = event.nativeEvent.contentSize.height;
    if (newHeight > 240) {
      return;
    }
    setInputHeight(newHeight > INITIAL_HEIGHT ? newHeight : INITIAL_HEIGHT);
  };

  const resetHeight = () => {
    setInputHeight(INITIAL_HEIGHT);
  };

  const handleChangeText = (text) => {
    if (text.length === 0) {
      resetHeight();
    }
    onChangeText(text);
  };

  const handleKeyPress = (e) => {
    if (screenReaderIsEnabled) {
      return;
    }
    if (e.nativeEvent.key === "Enter" && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      onSubmitEditing && onSubmitEditing();
      resetHeight();
    }
  };

  return (
    <View
      style={[isMedium ? styles.barWrapperMedium : styles.mobileBarWrapper, styles.barWrapper, shadowStyles.primary]}
      aria-label="Chat bar">
      {isMedium && <QuickOptionListContainer onQuickOptionPress={onQuickOptionPress} />}
      <View style={[styles.chatWrapper, isMedium ? { gap: 24 } : { gap: 4 }]}>
        {showChatWindow && (
          <Pressable
            role="button"
            aria-label="Expand chat"
            accessibilityHint="Press to display chat window"
            style={[
              styles.expandChatButton,
              isMedium
                ? { height: 48, width: 48 }
                : {
                    height: 36,
                    width: 36,
                  },
            ]}
            onPress={onToggleChatPress}>
            <ExpandChatIcon />
          </Pressable>
        )}
        <View
          style={[
            styles.container,
            { height: Math.min(inputHeight, 240), borderRadius },
            isRecording ? styles.recording : null,
          ]}>
          <TextInput
            id="chatInput"
            ref={inputRef}
            value={value}
            maxLength={7500}
            multiline
            numberOfLines={7}
            placeholder={placeholder}
            placeholderTextColor="#808080"
            style={[
              styles.textInput,
              isMedium ? styles.textInputMedium : styles.textInputMobile,
              { height: inputHeight },
            ]}
            onChangeText={handleChangeText}
            onSubmitEditing={onSubmitEditing}
            onKeyPress={handleKeyPress}
            onContentSizeChange={handleContentSizeChange}
            scrollEnabled={true}
            aria-label="Chat text input"
          />

          <View style={styles.buttons}>
            {value !== "" ? (
              <CustomButton
                text=""
                rightIcon={null}
                textStyle={{}}
                disabled={false}
                leftIcon={<SubmitIcon />}
                styleType="transparent"
                style={styles.button}
                onPress={onSubmitEditing}
                textProps={{}}
                aria-label="Submit chat"
              />
            ) : isRecording ? (
              <CustomButton
                text=""
                rightIcon={null}
                textStyle={{}}
                disabled={false}
                leftIcon={<WaveFormClose />}
                styleType="transparent"
                style={styles.stopButton}
                onPress={onRecordingStopPress}
                textProps={{}}
                aria-label="Stop recording"
              />
            ) : (
              <CustomButton
                text=""
                rightIcon={null}
                textStyle={{}}
                disabled={false}
                leftIcon={<WaveForm />}
                styleType="transparent"
                style={styles.button}
                onPress={onRecordingStartPress}
                textProps={{}}
                aria-label="Start recording"
              />
            )}
            {isAudioOn ? (
              <CustomButton
                leftIcon={<AudioOn />}
                styleType="transparent"
                style={styles.button}
                onPress={onAudioOffPress}
                text=""
                rightIcon={null}
                textStyle={{}}
                disabled={false}
                textProps={{}}
                aria-label="Turn off audio"
              />
            ) : (
              <CustomButton
                leftIcon={<AudioOff />}
                styleType="transparent"
                style={styles.button}
                onPress={onAudioOnPress}
                text=""
                rightIcon={null}
                textStyle={{}}
                disabled={false}
                textProps={{}}
                aria-label="Turn on audio"
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  barWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 999,
    width: "100%",
    maxWidth: 1250,
  },
  barWrapperMedium: {
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    paddingVertical: 20,
    paddingHorizontal: 40,
    gap: 16,
  },
  mobileBarWrapper: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 10,
    gap: 10,
  },
  chatWrapper: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  expandChatButton: {
    backgroundColor: "#F2F4F7",
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F2F4F7",
    elevation: 5,
    justifyContent: "space-between",
  },
  textInput: {
    flex: 1,
    fontWeight: "400",
    fontFamily: "Inter",
    color: "#808080",
    outlineStyle: "none",
    textAlignVertical: "top",
    maxHeight: 500,
  },
  textInputMedium: {
    paddingHorizontal: 28,
    paddingVertical: 12,
    fontSize: 16,
  },
  textInputMobile: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    fontSize: 14,
  },
  recording: {
    borderColor: "#3EFE5D",
    borderWidth: 4,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  button: {
    padding: 0,
  },
  stopButton: {
    padding: 0,
    bottom: -2,
  },
});

export default ChatBar;
