import React, { useCallback } from "react";
import { useAppState } from "../contexts/AppStateContext";
import { useAuth } from "../hooks/useAuth";
import { useNavigation, useRoute, useFocusEffect } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import WebSocketNavigator from "../navigation/WebSocketNavigator";
import { useChatBar, ConversationType } from "../contexts/ChatBarContext";

const withAuth = (WrappedComponent) => {
  const WithAuthComponent = (props) => {
    const { state } = useAppState();
    const { isAuthenticated, isEmailVerified, otpVerifiedForSession } = useAuth();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const route = useRoute();
    const name = route.name;
    const params = route.params;
    const { mapRouteToContext, setActiveContextAndObjectId, setContext, setConversationType } = useChatBar();

    WebSocketNavigator();

    useFocusEffect(
      useCallback(() => {
        const activeContext = mapRouteToContext(name as keyof RootStackParamList);

        let objectId = null;
        switch (activeContext) {
          case "track":
            objectId = (params as RootStackParamList["Lesson Chat"]).loId;
            break;
          case "assignment":
            objectId = (params as RootStackParamList["Resource"]).resource_id;
            break;
          case "journey":
            objectId = (params as RootStackParamList["Journey"]).id;
            break;
          case "onboarding":
          case "general":
          default:
            objectId = null;
            break;
        }

        let contentType = null;
        switch (activeContext) {
          case "track":
            contentType = ConversationType.LEARNING;
            break;
          case "assignment":
            contentType = ConversationType.SCHOOL;
            break;
          case "journey":
            contentType = ConversationType.JOURNEY;
            break;
          case "onboarding":
            contentType = ConversationType.ONBOARD;
            break;
          case "general":
          default:
            contentType = ConversationType.GENERAL_CONVERSATION;
            break;
        }

        setConversationType(contentType);
        setActiveContextAndObjectId({ context: activeContext, objectId });
        setContext({ context: activeContext, params: params as { [key: string]: string } });
      }, [])
    );

    useFocusEffect(
      useCallback(() => {
        if (!isAuthenticated) {
          navigation.navigate("Login");
        } else if (!isEmailVerified || !otpVerifiedForSession) {
          navigation.navigate("Login");
        } else if (state.meta.isOnboarded === false) {
          navigation.navigate("Onboarding");
        }
      }, [isAuthenticated, isEmailVerified, otpVerifiedForSession, state.meta.isOnboarded])
    );

    if (!isAuthenticated) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuthComponent;
};

export default withAuth;
