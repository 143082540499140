import React from "react";
import { View } from "react-native";
import Placeholder from "../../general/Placeholder/Placeholder";
import ItemCard from "../ItemCard/ItemCard";
import { MotiView } from "moti";
import { useAppConfig } from "../../../AppConfigProvider";

function ItemCardList({ items = [], onPress }) {
  const renderItemCard = (item, index) => {
    const id = item.id;
    const title = item.details?.title || item.title;
    const dueDate = item.details?.due_date || item.due_date;
    const appConfig = useAppConfig();
    const isMoodleOffering = appConfig.isMoodleOffering;

    const externalLink = item.properties?.canvas?.external_tool_tag_attributes?.url;

    const getType = (item) => {
      if (item.type) return item.type;
      if (item.details && item.details.type) return item.details.type;
      if (item.resources && item.resources.length > 0) {
        return item.resources[0].type;
      }
      return null;
    };

    const label = (() => {
      const type = getType(item);
      switch (type) {
        case "assignment":
          return isMoodleOffering ? "Activity" : "Assignment";
        case "page":
          return "Material";
        default:
          return isMoodleOffering ? "Activity" : "Assignment";
      }
    })();

    const abbreviatedClassName = item.className
      ? item.className.length > 16
        ? item.className.substring(0, 16) + "..."
        : item.className
      : "";

    return (
      <MotiView
        key={id}
        from={{
          opacity: 0,
          translateY: 20,
          scale: 0.95,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={300 + index * 300}
        transition={{ type: "timing", duration: 800 }}>
        <ItemCard
          key={id}
          title={title}
          isHover={item.isHover}
          dueDate={dueDate}
          label={label}
          className={abbreviatedClassName}
          onPress={() => onPress(id, externalLink ? item.url : null)}
        />
      </MotiView>
    );
  };

  return (
    <View>
      {items.length > 0 ? (
        items.map(renderItemCard)
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Placeholder subtitle="Visit your Learning Management System to access these resources" />
        </View>
      )}
    </View>
  );
}

export default ItemCardList;
