import React from "react";
import { FlatList } from "react-native";
import Task from "../Task/Task";
import Placeholder from "../../general/Placeholder/Placeholder";
import { MotiView } from "moti";
import { sortTasks } from "../../../utils/array";

function TaskList({ tasks, onCheckboxToggle, onJourneyPathPress, isJourneyView, onTaskPress }) {
  const renderItem = ({ item, index }) => {
    return (
      <MotiView
        key={item.id}
        from={{
          opacity: 0,
          translateY: 20,
          scale: 0.95,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        style={{ flex: 1, margin: 3 }}
        delay={0}
        transition={{ type: "timing", duration: 800 }}>
        <Task
          id={item.id}
          title={item.name}
          description={item.description}
          onCheckboxToggle={() => onCheckboxToggle(item.id)}
          onJourneyPathPress={() => onJourneyPathPress(item.journeyId)}
          completed={item.completed}
          priority={item.priority}
          date={item.date}
          index={index}
          isPartOfJourney={item.isPartOfJourney}
          onTaskPress={() => onTaskPress(item.id)}
          isJourneyView={isJourneyView}
          isRepeating={item.isRecurring}
          isDeleted={item.deleted}
          isMoved={item.moved}
        />
      </MotiView>
    );
  };

  return (
    <FlatList
      showsVerticalScrollIndicator={false} // Add this line to hide the vertical scroll bar
      data={sortTasks(tasks)}
      renderItem={renderItem}
      keyExtractor={(item) => item.id}
      ListEmptyComponent={() => (
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 0.95,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          style={{ flex: 1 }}
          delay={1000}
          transition={{ type: "timing", duration: 800 }}>
          <Placeholder
            title="Ready to get things done?"
            subtitle="Add your first task and start checking things off your list!"
          />
        </MotiView>
      )}
    />
  );
}

export default TaskList;
