import React, { useState, useMemo, useEffect } from "react";
import { getWelcomeOnboardingModalData } from "../../../constants/onboarding";
import { useMeQuery, useUpdateUserModalOnboardingMutation } from "../../../graphql/generated/graphql";
import OnboardingModal from "../../../components/onboarding/OnboardModal/OnboardModal";
import { useAppConfig } from "../../../AppConfigProvider";
import { getEdlinkLoginUrl } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform, Linking, Text, StyleSheet } from "react-native";
import { announceForAccessibility } from "../../../utils/accessibility";

const OnboardModalContainer = ({ visible, onClose }) => {
  const appConfig = useAppConfig();
  const isLMS = appConfig.isLMS || false;
  const isLTI = appConfig.isLTI || false;
  const companionName = appConfig.companionName;
  const contactEmail = appConfig.bugReportEmails;
  const termsOfServiceLink = appConfig.termsOfServiceLink;
  const [modalIndex, setModalIndex] = useState(1);
  const [updateModalOnboarding, loading] = useUpdateUserModalOnboardingMutation();
  const { data } = useMeQuery();

  useEffect(() => {
    if (Platform.OS === "ios" && !loading) {
      announceForAccessibility({ message: "Modal is loaded", queue: false });
    }
  }, [loading]);

  const modalsData = useMemo(() => getWelcomeOnboardingModalData(data?.me?.name, companionName), [modalIndex, data]);

  const currentModalData = modalsData[`step${modalIndex}`];

  const numberOfSteps = Object.keys(modalsData).length;

  const ctaButtonText = modalIndex === numberOfSteps ? "Done" : `Next (${modalIndex}/${numberOfSteps})`;

  const updateModalOnboardingFunction = async () => {
    const modalOnboarding = data?.me?.modal_onboarding;

    await updateModalOnboarding({
      variables: {
        modal_onboarding: modalOnboarding ? modalOnboarding + ",welcome" : "welcome",
      },
    });
  };

  const handleConnectSchool = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");

    getEdlinkLoginUrl(token).then(async (response) => {
      const url = response.loginUrl;
      await handleOpenLink(url, newWindow);
    });
  };

  const handleNext = async () => {
    if (modalIndex === numberOfSteps) {
      onClose();
      await updateModalOnboardingFunction();
      return;
    }

    setModalIndex((prevIndex) => prevIndex + 1);
  };

  const handleTermsPress = () => {
    Linking.openURL(termsOfServiceLink);
  };

  return (
    <>
      {isLMS ? (
        <OnboardingModal
          visible={visible}
          title={"Connect to your LMS"}
          description={
            "By connecting to your school's Learning Management System, you can access your courses and assignments directly from Primer. This is a mandatory step for unlocking your full account. If you have any issues connecting, please contact us at " +
            contactEmail +
            "."
          }
          footer={null}
          onCtaClick={handleConnectSchool}
          image={currentModalData?.image}
          ctaButtonText={"Connect"}
          aspectRatio={currentModalData?.aspectRatio}
          height={currentModalData?.imageHeight}
          loading={loading?.loading}
        />
      ) : isLTI ? (
        <OnboardingModal
          visible={visible}
          title={currentModalData?.title}
          description={`${currentModalData?.description} By continuing you agree to the terms of service and privacy policy below.`}
          footer={
            <Text role="link" style={styles.termsLink} onPress={handleTermsPress}>
              Terms of Service and Privacy Policy
            </Text>
          }
          onCtaClick={handleNext}
          image={currentModalData?.image}
          ctaButtonText={ctaButtonText}
          aspectRatio={currentModalData?.aspectRatio}
          height={currentModalData?.imageHeight}
          loading={loading?.loading}
        />
      ) : (
        <OnboardingModal
          visible={visible}
          title={currentModalData?.title}
          description={currentModalData?.description}
          footer={null}
          onCtaClick={handleNext}
          image={currentModalData?.image}
          ctaButtonText={ctaButtonText}
          aspectRatio={currentModalData?.aspectRatio}
          height={currentModalData?.imageHeight}
          loading={loading?.loading}
        />
      )}
    </>
  );
};

export default OnboardModalContainer;

const styles = StyleSheet.create({
  termsLink: {
    color: "#3E68FE",
    textDecorationLine: "underline",
  },
});
