import { useContext, useCallback, useEffect, useState, useRef } from "react";
import { WebSocketContext } from "../contexts/WebSocketContext";
import { useAppState } from "../contexts/AppStateContext";
import { dispatchActions } from "../utils/dispatchActions";
import { useChatBar } from "../contexts/ChatBarContext";
import Sentry from "../utils/sentry";

const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  const [messageQueue, setMessageQueue] = useState([]);
  const { dispatch } = useAppState();
  const { setMetaIsLoading } = useChatBar();
  const pingInterval = useRef(null);

  if (context === null) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }

  const { socket, reconnect, sendHandler } = context;

  const handleDispatch = useCallback(
    (message) => {
      dispatchActions(dispatch, message);
    },
    [dispatch]
  );

  // Add message to the queue if WebSocket is not open
  const sendMessage = useCallback(
    async (message, onSuccess = () => {}) => {
      const result = await sendHandler(message);

      if (result) {
        handleDispatch(message);
        onSuccess();
      } else {
        setMessageQueue((prevQueue) => [...prevQueue, { message, onSuccess }]);
        reconnect();
      }
    },
    [socket, reconnect, handleDispatch]
  );

  // Flush the message queue when the WebSocket becomes open
  useEffect(() => {
    const handleOnMessage = (message) => {
      const messageData = JSON.parse(message.data);
      if (message.type === "pong") {
        //no action needed
      } else {
        handleDispatch(messageData);
        if (messageData.content?.message || messageData.meta?.abort) {
          setMetaIsLoading(false);
        }
      }
    };

    const handleOpen = () => {
      if (messageQueue.length > 0) {
        messageQueue.forEach((queued) => {
          const { message, onSuccess } = queued;
          socket.send(JSON.stringify(message));
          handleDispatch(message);
          if (onSuccess) {
            onSuccess();
          }
        });
        setMessageQueue([]);
      }

      startHeartbeat();
    };

    const startHeartbeat = () => {
      if (pingInterval.current) {
        clearInterval(pingInterval.current);
      }

      pingInterval.current = setInterval(() => {
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ type: "ping" }));
        }
      }, 30000); // 30 seconds
    };

    const handleClose = () => {
      clearInterval(pingInterval.current);
      reconnect();
    };

    const handleError = (error) => {
      Sentry.captureException(error);
      reconnect();
    };

    if (socket) {
      socket.addEventListener("open", handleOpen);
      socket.addEventListener("close", handleClose);
      socket.addEventListener("error", handleError);
      socket.addEventListener("message", handleOnMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener("open", handleOpen);
        socket.removeEventListener("close", handleClose);
        socket.removeEventListener("error", handleError);
        socket.removeEventListener("message", handleOnMessage);
      }
      clearInterval(pingInterval.current);
    };
  }, [socket, messageQueue, handleDispatch, reconnect]);

  return { socket, sendMessage };
};

export default useWebSocket;
